/**
 * @name dwtCreatePEClaimModule
 * @description This is a module used for create PE claim of DWT. All the
 *              navigations belonging to PE claim happen here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('dwtCreatePEClaimModule', ['ui.router', 'ngStorage', 'ngResource'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('createPEClaim', {
          url: '/createPEClaim',
          params: {
            selectedIndex: 2,
            peClaimId: null,
            eventId: null,
            shareholderId: null,
            intermediaryId: null,
            loginUserId: null,
            isin: null,
            dwtPerSecurity: null,
            issuerId: null,
            flag: null,
            eventStatus: null,
            eventName: null,
            issuerName: null,
            qrfEndDate: null,
            extensionReqFlagForQRF: null,
            extensionReqFlagForPE: null,
            extReqFlag: null,
            qrfFlag: null,
            peFlag: null,
            shareClassName: null,
            shareClassId: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-dwt-pe-claim/create-dwt-pe-claim.html',
              controller: 'CreateDwtPERequestController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_createPeClaim'],
          },
        })
        .state('createPEClaimDetail', {
          url: '/createPEClaimDetail',
          params: {
            selectedIndex: 2,
            peClaimId: null,
            eventId: null,
            shareholderId: null,
            intermediaryId: null,
            loginUserId: null,
            isin: null,
            dwtPerSecurity: null,
            issuerId: null,
            flag: null,
            eventStatus: null,
            eventName: null,
            issuerName: null,
            qrfEndDate: null,
            extensionReqFlagForQRF: null,
            extensionReqFlagForPE: null,
            extReqFlag: null,
            qrfFlag: null,
            peFlag: null,
            shareClassName: null,
            shareClassId: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-dwt-pe-claim/create-dwt-pe-claim.html',
              controller: 'CreateDwtPERequestController',
            },
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_dwtEventDetails',
              'label_createPeClaim',
            ],
          },
        })
        .state('editPEClaim', {
          url: '/editPEClaim',
          params: {
            selectedIndex: 2,
            peClaimId: null,
            eventId: null,
            shareholderId: null,
            intermediaryId: null,
            loginUserId: null,
            isin: null,
            dwtPerSecurity: null,
            flag: null,
            eventStatus: null,
            eventName: null,
            issuerId: null,
            issuerName: null,
            qrfEndDate: null,
            extReqFlag: null,
            extensionReqFlagForQRF: null,
            extensionReqFlagForPE: null,
            qrfFlag: null,
            peFlag: null,
            shareClassName: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-dwt-pe-claim/create-dwt-pe-claim.html',
              controller: 'CreateDwtPERequestController',
            },
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_dwtEventDetails',
              'label_editPEClaim',
            ],
          },
        });
    },
  ]);
