!(function(mydoc) {
  'use strict';

  angular.module(
    'captchamodule',
    ['noCAPTCHA', 'ngStorage']).config(
    ['noCAPTCHAProvider', 'siteKey',
      function(noCaptchaProvider, siteKey) {
        noCaptchaProvider.reset;
        noCaptchaProvider.setSiteKey(siteKey);
        noCaptchaProvider.setTheme('light');
      }]).controller(
    'captchaCtrl',
    ['$scope', 'captchaService',
      function($scope, captchaService) {
        var vm = this;
        vm.gRecaptchaResponse = undefined;
        vm.goAhead = false;
        $scope.$watch(function() {
          return vm.gRecaptchaResponse;
        }, function(newValue, oldValue) {
          vm.expired = false;
          if (newValue !== oldValue) {
            captchaService.setResponseToken(vm.gRecaptchaResponse);
          }
        }
        );
        vm.expiredCallback = function () {
          vm.expired = true;
        };
      }]);
  angular.module('captchamodule').constant('siteKey', siteKey);
})(window.document);
