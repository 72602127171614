/* eslint-disable indent */
import './voting-results.scss';
import { fetchAgendaItemVotesForMeeting } from '@/api/v2/gm/agendaItemVotes';
import { trimEncodedKey } from '@/lib/encodedKey';
import stripHtml from '@/lib/stripHtml';

export default {
  bindings: {
    onShareClassChange: '&',
  },
  controller: [
    '$scope',
    'voteCollectorService',
    'participationMethodService',
    '$log',
    '$sessionStorage',
    '$filter',
    '$stateParams',
    'fileDownloadSvc',
    'cbpDownloadBlob',
    '$interval',
    '$rootScope',
    '$http',
    function (
      $scope,
      voteCollectorService,
      participationMethodService,
      $log,
      $sessionStorage,
      $filter,
      $stateParams,
      fileDownloadSvc,
      cbpDownloadBlob,
      $interval,
      $rootScope,
      $http
    ) {
      const self = this;

      $scope.availablePartcipationMethods = [];
      $scope.participationMethodPassed = $stateParams.participationMethod ?
          $stateParams.participationMethod : 'ALL' ;
      $scope.selectedParticipationMethod = 'ALL';
      this.$onInit = async () => {
        $scope.meetingId =
          $scope.meetingId ||
          $stateParams.meetingId ||
          $stateParams.data?.meetingId;
        await this.fetchVotingData();
        this.processVoteData();
        $scope.availablePartcipationMethods = [
          {
            value: 'ALL',
            name: 'All',
          },
          ...participationMethodService.getVotingParticipationMethods()];
        $scope.$apply();
      };

      this.fetchVotingData = async () => {
        const agendaItemVotes = await fetchAgendaItemVotesForMeeting(
            $http,
            $scope.meetingId
        );
        $scope.agendaItemVotes = agendaItemVotes;
        $scope.hasVotes = !!agendaItemVotes.find((agendaItem) => {
          const totalVotes = this.calculateVotesCount(agendaItem);
          return (
              totalVotes?.forCount ||
              totalVotes?.againstCount ||
              totalVotes?.abstainCount
          );
        });
      };

      this.calculateVotesCount = (agendaItem) => {
        return {
          forCount: agendaItem?.votes?.reduce(
              function (result, item) {
                return result + item.votesDetail.forCount;
              }, 0),
          againstCount: agendaItem?.votes?.reduce(
              function (result, item) {
                return result + item.votesDetail.againstCount;
              }, 0),
          abstainCount: agendaItem?.votes?.reduce(
              function (result, item) {
                return result + item.votesDetail.abstainCount;
              }, 0)
        };
      };

      this.selectedVoteDetailsForAgendaItem = (agendaItem) => {
        // Copying data to local variable to maintain original state in scope variable
        const localAgendaItem = angular.copy(agendaItem);
        $scope.hasVotes = true;
        let votesDetail;
        if ($scope.selectedShareClass === 'all') {
          if ($scope.selectedParticipationMethod === 'ALL') {
            votesDetail = this.calculateVotesCount(agendaItem);
          } else {
            localAgendaItem.votes = agendaItem.votes.filter(
                (scv) => scv.participationMethod
                    === $scope.selectedParticipationMethod
            );
            votesDetail = this.calculateVotesCount(localAgendaItem);
          }
        } else {
          const shareClassId = trimEncodedKey($scope.selectedShareClass);
          if ($scope.selectedParticipationMethod === 'ALL') {
            localAgendaItem.votes = agendaItem.votes.filter(
                (scv) => scv.shareClassId === shareClassId
            );
            votesDetail = this.calculateVotesCount(localAgendaItem);
          } else {
            localAgendaItem.votes = agendaItem.votes.filter(
                (scv) => scv.shareClassId === shareClassId
                    &&
                    scv.participationMethod
                    === $scope.selectedParticipationMethod
            );
            votesDetail = this.calculateVotesCount(localAgendaItem);
          }
        }
        if (!votesDetail) {
          $scope.hasVotes = false;
          return {};
        } else {
          return votesDetail;
        }
      };

      $scope.barChartOptions = {
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            type: 'linear',
            min: 0,
            max: 100,
            title: {
              display: true,
              text: 'Percentage of votes (%)',
              font: {
                weight: 'bold',
              },
            },
          },
          y: { stacked: true },
        },
        datasets: {
          bar: { barThickness: 20 },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (ctx) => `${ctx.formattedValue}%`,
            },
          },
        },
      };

      var vc = this;
      if ($sessionStorage.gmLang) {
        $scope.languageCode = $sessionStorage.gmLang;
      } else {
        $scope.languageCode = 'EN';
      }
      if ($sessionStorage.status) {
        $scope.gmStatusforVC = $sessionStorage.status;
      } else {
        $scope.gmStatusforVC = 'M';
      }

      vc.documents = [
        {
          fileVo: [
            {
              file: '',
              fileName: '',
            },
          ],
        },
      ];

      this.processVoteData = () => {
        $scope.userType = $sessionStorage.usertype;

        if ($stateParams.status === 'M') {
          $scope.shareClass = $sessionStorage.gmShareclassList;
          $scope.shareClassOptions = [
            {
              name: 'All',
              value: 'all',
            },
            ...$scope.shareClass.map((sc) => ({
              name: sc.shareClassName,
              value: sc.shareClassId,
            })),
          ];
          $scope.selectedShareClass =
            $scope.shareClass.length === 1
              ? $scope.shareClass[0].shareClassId
              : 'all';
          $scope.downloadRegXls = $scope.shareClass[0].regVoteCollectorWrkst;
          initBarChart(true);
        } else {
          if ($scope.userType === 'VC') {
            $scope.selectedIndex = 0;
            $scope.meetingId = $stateParams.data.meetingId;

            $scope.displayVoteConfirmTab =
              $stateParams.data.displayVoteConfirmTab;
            $scope.agendaItems = $stateParams.data.agendaItems;
            $scope.issuerId = $stateParams.data.issuerId;
          }

          voteCollectorService
            .getVCShareclassList(
              $scope.meetingId,
              $scope.userType,
              $scope.languageCode
            )
            .then(
              function (response) {
                $scope.shareClass = response.data;
                $scope.shareClassOptions = [
                  {
                    name: 'All',
                    value: 'all',
                  },
                  ...$scope.shareClass.map((sc) => ({
                    name: sc.shareClassName,
                    value: sc.shareClassId,
                  })),
                ];

                if ($scope.shareClass.length > 0) {
                  $scope.selectedShareClass =
                    $scope.shareClass.length === 1
                      ? $scope.shareClass[0].shareClassId
                      : 'all';

                  $scope.downloadRegXls =
                    $scope.shareClass[0].regVoteCollectorWrkst;
                  initBarChart(true);
                } else {
                  $log.debug(response);
                }
              },
              function (error) {
                $log.debug(error);
              }
            );
        }

        $scope.languageCode = $scope.languageCode || 'EN';
        $scope.successMessage = '';
        $scope.formDataVC = '';
        //Below are the variables used for displaying voting results bar chart.
      };

      $scope.barChartColours = [
        {
          backgroundColor: '#009286',
        },
        {
          backgroundColor: '#F3C000',
        },
        {
          backgroundColor: 'rgba(136, 136, 136, 0.33)',
        },
      ];

      const initBarChart = (votingIsDone = false) => {
        if (votingIsDone) {
          $scope.votingIsDone = true;
        }

        const forPercentages = $scope.agendaItemVotes.map((agendaItem) => {
          if (!agendaItem.agendaItemDetail.votable) {
            return 0;
          }

          const {
            forCount,
            againstCount,
          } = self.selectedVoteDetailsForAgendaItem(agendaItem);
          return (forCount / (forCount + againstCount)) * 100;
        });

        const againstPercentages = $scope.agendaItemVotes.map((agendaItem) => {
          if (!agendaItem.agendaItemDetail.votable) {
            return 0;
          }

          const {
            forCount,
            againstCount,
          } = self.selectedVoteDetailsForAgendaItem(agendaItem);
          return (againstCount / (forCount + againstCount)) * 100;
        });

        if (!$scope.hasVotes) {
          return;
        }

        const barChartLabels = $scope.agendaItemVotes.map(
          ({ agendaItemDetail }) =>
            `${agendaItemDetail.agendaIndexCaption}. ${stripHtml(
              agendaItemDetail.title
            )}`
        );

        const series = ['For', 'Against'];

        $scope.barChartData = {
          labels: barChartLabels,
          datasets: [
            {
              data: forPercentages,
              label: series[0],
              backgroundColor: $scope.barChartColours[0].backgroundColor,
            },
            {
              data: againstPercentages,
              label: series[1],
              backgroundColor: $scope.barChartColours[1].backgroundColor,
            },
          ],
        };
      };

      $scope.handleParticipationMethodChange = function (event) {
        const {value} = event.detail;
        $scope.selectedParticipationMethod = value;
        this.onShareClassValueChange($scope.selectedShareClass);
      };

      $scope.handleShareClassChange = function (ev) {
        const {value} = ev.detail;
        $scope.selectedShareClassId = value;
        this.onShareClassValueChange(value);
      };

      $scope.onShareClassValueChange = function (value) {
        if (value === 'all') {
          $scope.selectedShareClass = value;
        } else {
          $scope.selectedShareClass = value;
          self.onShareClassChange({shareClassId: value});
          angular.forEach($scope.shareClass, function (shareClassObj, index) {
            if ($scope.shareClass[index].shareClassId === value) {
              $scope.downloadRegXls =
                  $scope.shareClass[index].regVoteCollectorWrkst;
            }
          });
        }
        initBarChart();
      };

      $scope.downloadVotingResultNew = function (shareClassId, format) {
        $scope.loading = true;
        var stop;

        let triggerUrl = `/v1/votingresults/general-meetings/${$scope.meetingId}/voting-instructions-per-registration-reports`;
        triggerUrl += `?format=${format}`;
        if (shareClassId !== 'all') {
          triggerUrl += `&shareclasses=${shareClassId}`;
        }
        if ($scope.selectedParticipationMethod !== 'all') {
          triggerUrl += `&participationMethods=${$scope.selectedParticipationMethod}`;
        }

        $scope.stopService = function () {
          if (angular.isDefined(stop)) {
            $interval.cancel(stop);
            stop = undefined;
          }
        };
        voteCollectorService.getExecutionId(triggerUrl).then(
          function (response) {
            stop = $interval(function () {
              var jobExecutionUrl = 'v1/jobs/' + response.data.jobExecutionId;
              var promise = voteCollectorService.downloadProgressService(
                jobExecutionUrl
              );
              promise.then(
                function (data) {
                  if (
                    data.data.exitCode === 'FAILED' ||
                    data.data.exitCode === 'COMPLETED' ||
                    data.data.exitCode === 'STOPPED'
                  ) {
                    if (data.data.exitCode === 'COMPLETED') {
                      const downloadUrl =
                        `v1/votingresults/voting-instructions-per-registration-reports-jobs/${response.data.jobExecutionId}`;
                      const a = document.createElement('a');
                      document.body.appendChild(a);
                      fileDownloadSvc.downloadFile(downloadUrl).then(
                        function (fileResponse) {
                          if (!$scope.loading) {
                            return;
                          }

                          let fileVO = fileResponse;
                          if (window.navigator.msSaveOrOpenBlob) {
                            a.onclick = function () {
                              window.navigator.msSaveOrOpenBlob(
                                fileVO.file,
                                fileVO.fileName
                              );
                            };
                            a.click();
                          } else {
                            var fileURL = URL.createObjectURL(fileVO.file);
                            a.href = fileURL;
                            a.download = fileVO.fileName;
                            a.click();
                          }
                          $scope.loading = false;
                        },
                        function (error) {
                          $log.debug(error);
                          $scope.loading = false;
                        }
                      );
                    } else {
                      $scope.loading = false;
                      if (
                        $rootScope.globalErrorOpened === false ||
                        $rootScope.globalErrorOpened === undefined
                      ) {
                        $('#globalError').modal('show');
                        $rootScope.globalErrorOpened = true;
                      }
                    }
                    $scope.stopService();
                  }
                },
                function (error) {
                  $scope.loading = false;
                  if (
                    $rootScope.globalErrorOpened === false ||
                    $rootScope.globalErrorOpened === undefined
                  ) {
                    $('#globalError').modal('show');
                    $rootScope.globalErrorOpened = true;
                  }
                }
              );
            }, 3000);
          },
          function (error) {
            $scope.loading = false;
            $log.debug(error);
            if (
              $rootScope.globalErrorOpened === false ||
              $rootScope.globalErrorOpened === undefined
            ) {
              $('#globalError').modal('show');
              $rootScope.globalErrorOpened = true;
            }
          }
        );
      };

      $scope.downloadTotalVotingInstruction = function (shareClassId, format) {
        $scope.loading = true;
        let downloadUrl =
          `v1/votingresults/general-meetings/${$scope.meetingId}/voting-instructions-summary-reports`;

        downloadUrl += `?format=${format}`;
        if (shareClassId !== 'all') {
          downloadUrl += `&shareclasses=${shareClassId}`;
        }

        let a = document.createElement('a');
        document.body.appendChild(a);
        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            if (!$scope.loading) {
              return;
            }

            let fileVO = response;
            if (window.navigator.msSaveOrOpenBlob) {
              a.onclick = function () {
                window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
              };
              a.click();
            } else {
              var fileURL = URL.createObjectURL(fileVO.file);
              a.href = fileURL;
              a.download = fileVO.fileName;
              a.click();
            }
            downloadUrl = '';
            $scope.loading = false;
          },
          function (error) {
            $scope.loading = false;
            $log.debug(error);
          }
        );
      };

      $scope.downLoadRegListExcelNew = function (shareClassId) {
        $scope.loading = true;
        let downloadLink = `/v1/general-meetings/${$scope.meetingId}/registrations-reports?format=excel`;
        if (shareClassId !== 'all') {
          downloadLink += `&shareclasses=${shareClassId}`;
        }

        fileDownloadSvc.downloadFile(downloadLink).then(
          function (response) {
            if (!$scope.loading) {
              // Request has been cancelled in the mean time
              return;
            }
            cbpDownloadBlob(response.file, response.fileName);
            $scope.loading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.loading = false;
          }
        );
      };

      $scope.$on('onLanguageChange', (event, lang) => {
        $scope.languageCode = lang;
        this.processVoteData();
      });

      $scope.cancelDownload = function () {
        $scope.loading = false;
      };
    },
  ],
  templateUrl:
    'features/modules/general-meeting-module/gm-vote-collector-view/voting-results/voting-results.template.html',
};
