// import styles from './voting-confirmations.module.scss';
import { userTypeToPathSegment } from '@/lib/workstationPathSegment';
import { trimEncodedKey } from '@/lib/encodedKey';

export default {
  bindings: {
    meetingId: '@',
    shareClassId: '@',
    userType: '@',
    firstPageVoteConfirmations: '<',
    totalItems: '@',
  },
  controller: [
    '$scope',
    '$http',
    function ($scope, $http) {
      this.$onInit = () => {
        this.isFetching = false;
        this.pageInfo = {
          pageNo: 1,
          pageSize: 20,
        };
        this.pageMeta = {
          totalPages: Math.ceil(this.totalItems / this.pageInfo.pageSize),
          totalElements: this.totalItems,
        };
        this.voteConfirmations = this.firstPageVoteConfirmations;
      };

      this.$onChanges = function (changeObj) {
        if (!changeObj.shareClassId.isFirstChange()) {
          this.fetchConfirmedVotes();
        }
      };

      this.fetchConfirmedVotes = async () => {
        this.error = null;
        this.isFetching = true;
        try {
          const response = await this.listConfirmedVotes(
            this.userType,
            trimEncodedKey(this.meetingId),
            trimEncodedKey(this.shareClassId),
            this.pageInfo
          );
          this.voteConfirmations = response.list;
          this.pageMeta = {
            totalElements: response.totalElements,
            totalPages: Math.ceil(response.totalElements / response.pageSize),
          };
        } catch (err) {
          this.error = err;
        } finally {
          this.isFetching = false;
          $scope.$apply();
        }
      };

      this.listConfirmedVotes = async (
        userType,
        meetingId,
        shareClassId,
        pageInfo
      ) => {
        const { data } = await $http.get(
          `/v2/gm/${userTypeToPathSegment(
            userType
          )}/general-meetings/${meetingId}/share-classes/${shareClassId}/vote-confirmations`,
          { params: { ...pageInfo } }
        );
        return data;
      };

      this.handlePaginationChange = (pageNo) => {
        this.pageInfo.pageNo = pageNo;
        this.fetchConfirmedVotes();
      };
    },
  ],
  templateUrl:
    'features/modules/general-meeting-module/gm-vote-collector-view/voting-confirmations/confirmed-votes/confirmed-votes.template.html',
};
