/**
 * @name shareHolderModule
 * @description This is a module used for Share Holder WS. All the navigations
 *              belonging to Share Holder happen here
 * @requires $state, $stateProvider
 */
'use strict';
angular
  .module('shareHolderModule', [
    'ui.router',
    'ngStorage',
    'commonModule',
    'ngResource',
    'ui.bootstrap',
    'ngAnimate',
    'ngSanitize',
    'ReorderModule',
    'UIControlModule',
    'richTextEditorModule',
    'gmModal',
    'myAccountProfile',
    'MenuModule',
  ])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('shareholder', {
          url: '/shareholder',
          params: {
            selectedIndex: 0,
            flag: 1,
          },
          data: {
            nameList: ['label_home'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/share-holder-module/share-holder-main/share-holder-landing.html',
              controller: 'shareHolderGMController',
            },
          },
          resolve: {
            issuerLogo: [
              'shareHolderService',
              function (shareHolderService) {
                return shareHolderService.getIssuerLogos(1).then(
                  function (data) {
                    return data;
                  },
                  function (error) {
                    return error;
                  }
                );
              },
            ],
          },
        })
        .state('shareHolderHome', {
          url: '/shareHolderHome',
          params: {
            selectedIndex: 0,
            flag: 1,
          },
          data: {
            nameList: ['label_home'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/share-holder-module/share-holder-main/share-holder-landing.html',
              controller: 'shareHolderGMController',
            },
          },
          resolve: {
            issuerLogo: [
              'shareHolderService',
              function (shareHolderService) {
                return shareHolderService.getIssuerLogos(1).then(
                  function (data) {
                    return data;
                  },
                  function (error) {
                    return error;
                  }
                );
              },
            ],
          },
        })
        .state('shareHolderLogout', {
          url: '/shareHolderLogout',
          views: {
            mainView: {
              template: '',
              // This for activating first tab
              controller: [
                '$window',
                function ($window) {
                  $window.open('shareholder', '_self');
                },
              ],
            },
          },
        })
        .state('shGeneralMeetings', {
          url: '/shGeneralMeetings',
          params: {
            selectedIndex: 1,
            flag: 0,
          },
          data: {
            nameList: ['label_generalMeeting1'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/share-holder-module/share-holder-gm/share-holder-gm.html',
              controller: 'shareHolderGMController',
            },
          },
          resolve: {
            issuerLogo: function () {
              var data = null;
              return data;
            },
          },
        })
        .state('shMyVotes', {
          url: '/shMyVotes',
          params: {
            selectedIndex: null,
            landingThrough: null,
            type: null,
            radioVal: null,
            dropdownVal: null,
          },
          data: {
            nameList: ['label_myVotes'],
          },
          views: {
            mainView: {
              templateUrl: 'features/modules/my-votes/my-votes.html',
              controller: 'MyVotesController',
            },
          },
        })
        .state('shMyVirtualParticipation', {
          url: '/shMyVirtualParticipation',
          params: {
            selectedIndex: null,
            selectedRequestStatus: null,
          },
          data: {},
          views: {
            mainView: {
              templateUrl:
                'features/modules/share-holder-module/my-virtual-attendance-registrations/my-va-registrations.html',
              controller: 'MyVaRegistrationsController',
            },
          },
        })
        .state('thankYou', {
          url: '/thankYou',
          params: {
            selectedIndex: 1,
            issuerId: null,
            gmId: null,
            shareClassName: null,
            moreThanOneShareClass: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl: 'features/modules/voting-instruction/thank-you.html',
              controller: 'ThankYouController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_votingInstructions',
              'label_thankYou',
            ],
          },
          resolve: {
            issuerLogo: [
              'userGroupService',
              '$stateParams',
              function (userGroupService, $stateParams) {
                return userGroupService
                  .getIssuerGroupLogo($stateParams.issuerId)
                  .then(
                    function (data) {
                      return data;
                    },
                    function (error) {
                      return error;
                    }
                  );
              },
            ],
          },
        })
        .state('shGeneralDetails', {
          url: '/shGeneralDetails',
          params: {
            selectedIndex: 1,
            showHideFlag: null,
            meetingId: null,
            issuerId: null,
            issuerName: null,
            status: null,
            intermediaryDate: null,
            radioVal: null,
            dropdownVal: null,
            landingThrough: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/general-details/general-details.html',
              controller: 'GeneralDetailsViewController',
            },
          },
          data: {
            nameList: ['label_myVotes', 'label_generalDetails'],
          },
        })
        .state('shVotingInstructions', {
          url: '/shVotingInstructions',
          params: {
            selectedIndex: 2,
            data: null,
            meetingId: null,
            issuerId: null,
            shareClassName: null,
            shareHolderId: null,
            sharePlanloginShareClassId: null,
            votingInstDetails: null,
            instructionId: null,
            fromPage: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/voting-instruction.html',
              controller: 'VotingInstructionController',
            },
          },
          data: {
            nameList: ['label_myVotes', 'label_createNewVI'],
          },
        })
        .state('shVotingInstructionsEdit', {
          url: '/shVotingInstructionsEdit',
          params: {
            selectedIndex: 2,
            data: null,
            meetingId: null,
            issuerId: null,
            issuerName: null,
            shareClassName: null,
            shareHolderId: null,
            sharePlanloginShareClassId: null,
            votingInstDetails: null,
            instructionId: null,
            fromPage: null,
            disabled: null,
            radioVal: null,
            dropdownVal: null,
            landingThrough: null,
            action: null,
            status: null,
            virtualMeetingFlag: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/voting-instruction.html',
              controller: 'VotingInstructionController',
            },
          },
          data: {
            nameList: ['label_myVotes', 'label_editVotingIns'],
          },
        })
        .state('shEditAttendance', {
          url: '/shEditAttendance',
          params: {
            selectedIndex: 1,
            gmId: null,
            reqId: null,
            issuerId: null,
            shareholderId: null,
            registrationNo: null,
            gmDate: null,
            intermediaryName: null,
            issuerName: null,
            breadCrumbDetails: [],
            isVirtualMeetingAllowed: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/edit-shareholder-attendance-request.html',
              controller: 'EditShAttendanceController',
            },
          },
          data: {
            nameList: ['label_myVotes', 'label_edit_IN_AR_BOFile'],
          },
          resolve: {
            issuerLogo: [
              'userGroupService',
              '$stateParams',
              function (userGroupService, $stateParams) {
                if ($stateParams.issuerId) {
                  return userGroupService
                    .getIssuerGroupLogo($stateParams.issuerId)
                    .then(
                      function (data) {
                        return data;
                      },
                      function (error) {
                        return error;
                      }
                    );
                }
              },
            ],
          },
        })
        .state('shVotingInstructionsView', {
          url: '/shVotingInstructionsView',
          params: {
            selectedIndex: 2,
            data: null,
            meetingId: null,
            issuerId: null,
            shareClassName: null,
            shareHolderId: null,
            sharePlanloginShareClassId: null,
            votingInstDetails: null,
            instructionId: null,
            fromPage: null,
            disabled: null,
            radioVal: null,
            dropdownVal: null,
            issuerName: null,
            landingThrough: null,
            action: null,
            numberOfSecurities: null,
            status: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/voting-instruction.html',
              controller: 'VotingInstructionController',
            },
          },
          data: {
            nameList: ['label_myVotes', 'label_viewVotingInstructions'],
          },
        })
        .state('shGMAttendanceRequest', {
          url: '/shGMAttendanceRequest',
          params: {
            selectedIndex: 1,
            data: null,
            issuerId: null,
            issuerName: null,
            gmId: null,
            sharePlanId: null,
            attendanceDataDeatils: null,
            breadCrumbDetails: [],
            virtualMeetingFlag: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/attendance-request.html',
              controller: 'AttendanceRequestController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_createNewAR'],
          },
        })
        .state('linkedMeetingAttendanceRequest', {
          url: '/linkedMeetingAttendanceRequest',
          params: {
            selectedIndex: 1,
            data: null,
            issuerId: null,
            issuerName: null,
            linkedISIN: null,
            gmId: null,
            sharePlanId: null,
            selectedIntermediaryName: null,
            attendanceDataDeatils: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/attendance-request.html',
              controller: 'AttendanceRequestController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_createNewAR'],
          },
        })
        .state('goToAttendanceReq', {
          url: '/goToAttendanceReq',
          params: {
            selectedIndex: null,
            data: null,
            issuerId: null,
            issuerName: null,
            gmId: null,
            sharePlanId: null,
            attendanceDataDeatils: null,
            virtualMeetingFlag: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/attendance-request.html',
              controller: 'AttendanceRequestController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_createNewAR'],
          },
        })
        .state('editGoToAttendanceReq', {
          url: '/editGoToAttendanceReq',
          params: {
            selectedIndex: 1,
            data: null,
            issuerId: null,
            issuerName: null,
            gmId: null,
            sharePlanId: null,
            attendanceDataDeatils: null,
            virtualMeetingFlag: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/attendance-request.html',
              controller: 'AttendanceRequestController',
            },
          },
          data: {
            nameList: ['label_myVotes', 'label_createNewAR'],
          },
        })
        .state('shGMVotingInstructions', {
          url: '/shGMVotingInstructions',
          params: {
            selectedIndex: 1,
            data: null,
            issuerName: null,
            meetingId: null,
            issuerId: null,
            shareClassName: null,
            shareHolderId: null,
            sharePlanloginShareClassId: null,
            votingInstDetails: null,
            instructionId: null,
            fromPage: null,
            virtualMeetingFlag: null,
            action: null
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/voting-instruction.html',
              controller: 'VotingInstructionController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_createNewVI'],
          },
        })
        .state('linkedMeetingVotingInstruction', {
          url: '/linkedMeetingVotingInstruction',
          params: {
            selectedIndex: 1,
            data: null,
            issuerName: null,
            meetingId: null,
            issuerId: null,
            shareClassName: null,
            shareHolderId: null,
            selectedIntermediaryName: null,
            sharePlanloginShareClassId: null,
            linkedISIN: null,
            votingInstDetails: null,
            instructionId: null,
            fromPage: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/voting-instruction.html',
              controller: 'VotingInstructionController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_createNewVI'],
          },
        })
        .state('goToVotingInstructions', {
          url: '/goToVotingInstructions',
          params: {
            selectedIndex: null,
            data: null,
            meetingId: null,
            issuerId: null,
            issuerName: null,
            shareClassName: null,
            shareHolderId: null,
            sharePlanloginShareClassId: null,
            votingInstDetails: null,
            instructionId: null,
            virtualMeetingFlag: null,
            fromPage: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/voting-instruction.html',
              controller: 'VotingInstructionController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_createNewVI'],
          },
        })
        .state('editGoToVotingInstructions', {
          url: '/editGoToVotingInstructions',
          params: {
            selectedIndex: 1,
            data: null,
            meetingId: null,
            issuerId: null,
            shareClassName: null,
            issuerName: null,
            shareHolderId: null,
            sharePlanloginShareClassId: null,
            votingInstDetails: null,
            instructionId: null,
            virtualMeetingFlag: null,
            fromPage: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/voting-instruction.html',
              controller: 'VotingInstructionController',
            },
          },
          data: {
            nameList: ['label_myVotes', 'label_createNewVI'],
          },
        })
        .state('shHomeAttendanceRequest', {
          url: '/shHomeAttendanceRequest',
          params: {
            selectedIndex: 0,
            data: null,
            issuerId: null,
            issuerName: null,
            gmId: null,
            sharePlanId: null,
            attendanceDataDeatils: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/attendance-request.html',
              controller: 'AttendanceRequestController',
            },
          },
          data: {
            nameList: ['label_home', 'label_createNewAR'],
          },
        })
        .state('shHomeGeneralMeetings', {
          url: '/shHomeGeneralMeetings',
          params: {
            selectedIndex: 1,
            meetingId: null,
            issuerId: null,
            issuerName: null,
            status: null,
            intermediaryDate: null,
            flag: null,
            showHideFlag: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/general-details/general-details.html',
              controller: 'GeneralDetailsViewController',
            },
          },
          data: {
            nameList: ['label_home', 'label_generalDetails'],
          },
        })
        .state('shViewVotingInstruction', {
          url: '/shViewVotingInstruction',
          params: {
            selectedIndex: null,
            meetingId: null,
            shareholderId: null,
            issuerId: null,
            shareclassId: null,
            intermediaryId: null,
            instructionId: null,
            issuerName: null,
            langCode: null,
            userType: null,
            votingInstructionData: null,
            status: null,
            numberOfSecurities: null,
            radioVal: null,
            dropdownVal: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/view-voting-instruction.html',
              controller: 'ViewVotingInstructionController',
            },
          },
          data: {
            nameList: ['label_myVotes', 'label_viewVotingInstructions'],
          },
        })
        .state('viewVotingReqIntermediary', {
          url: '/viewVotingReqIntermediary',
          params: {
            selectedIndex: 1,
            meetingId: null,
            shareholderId: null,
            issuerId: null,
            shareclassId: null,
            intermediaryId: null,
            instructionId: null,
            langCode: null,
            issuerName: null,
            userType: null,
            createdBy: null,
            votingInstructionData: null,
            status: null,
            numberOfSecurities: null,
            radioVal: null,
            dropdownVal: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/view-voting-instruction-intermediary.html',
              controller: 'viewVotingReqIntermediaryController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_voting_instruction',
              'label_viewVotingInstructions',
            ],
          },
        })
        .state('editVotingReqIntermediary', {
          url: '/editVotingReqIntermediary',
          params: {
            selectedIndex: 1,
            meetingId: null,
            shareholderId: null,
            issuerId: null,
            shareclassId: null,
            shareclassName: null,
            intermediaryId: null,
            instructionId: null,
            langCode: null,
            issuerName: null,
            userType: null,
            votingInstDetails: null,
            status: null,
            numberOfSecurities: null,
            radioVal: null,
            createdBy: null,
            dropdownVal: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/edit-voting-instruction-intermediary.html',
              controller: 'editVotingReqIntermediaryController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_voting_instruction',
              'label_editVotingIns',
            ],
          },
        })
        .state('vaRequestStep1', {
          url: '/virtual-attendance/request/step1',
          params: {
            selectedIndex: 1, //selectedIndex - For highlighting selected tab in header
            issuerId: null,
            gmId: null,
            fromPageStep2: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/share-holder-module/virtual-attendance-request/pages/va-request-step1/va-request-step1.html',
              controller: 'VaRequestStep1Controller',
            },
          },
          data: {},
        })
        .state('vaRequestStep2', {
          url: '/virtual-attendance/request/step2',
          params: {
            selectedIndex: null, //selectedIndex - For highlighting selected tab in header
            phoneCountryCode: null,
            phoneNumber: null,
            fromPageEdit: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/share-holder-module/virtual-attendance-request/pages/va-request-step2/va-request-step2.html',
              controller: 'VaRequestStep2Controller',
            },
          },
          data: {},
        })
        .state('vaRequestStep3', {
          url: '/virtual-attendance/request/step3',
          params: {
            selectedIndex: null, //selectedIndex - For highlighting selected tab in header
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/share-holder-module/virtual-attendance-request/pages/va-request-step3/va-request-step3.html',
              controller: 'VaRequestStep3Controller',
            },
          },
          data: {},
        })
        .state('vaRequestConfirmation', {
          url: '/virtual-attendance/request/confirmation',
          params: {
            selectedIndex: null, //selectedIndex - For highlighting selected tab in header
            intermediaryEmail: null,
            intermediaryName: null,
            intermediaryPhone: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/share-holder-module/virtual-attendance-request/pages/va-request-confirmation/va-request-confirmation.html',
              controller: 'VaRequestConfirmationController',
            },
          },
          data: {},
        })
        .state('2FAuthentication', {
          url: '/dualFactor/Authentication',
          params: {
            selectedIndex: 0, //selectedIndex - For highlighting selected tab in header
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/share-holder-module/dual-factor-authentication/dual-factor-authentication.html',
              controller: 'dualFactorAuthenticationCtrl',
            },
          },
          data: {},
        })
        .state('2FaConfirmation', {
          url: '/dualFactor/confirmation',
          params: {
            selectedIndex: null, //selectedIndex - For highlighting selected tab in header
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/share-holder-module/dual-factor-authentication/dual-factor-authentication-success.html',
            },
          },
          data: {},
        })
        .state('editVaRequestStep1', {
          url: '/virtual-attendance/request/edit/step1',
          params: {
            selectedIndex: 0, //selectedIndex - For highlighting selected tab in header
            issuerId: null, // issuerId - To get issuer details
            gmId: null, //gmId - To get Virtual Attendance Request details
            fromPageStep2: null, //fromPageStep2 - When come from vaRequestStep2 to step1
            virtualReqId: null, //virtualReqId - To get Virtual Attendance Request details
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/share-holder-module/virtual-attendance-request/pages/edit-va-step1/edit-va-step1.html',
              controller: 'editVaRequestStep1Controller',
            },
          },
          data: {},
        });
    },
  ]);
