export default [
  '$http',
  function ($http) {
    function list(meetingId, lang) {
      let endpoint = `/v2/gm/shareholder/general-meetings/${meetingId}/entitlements`;
      if (lang) {
        endpoint += `?language=${lang}`;
      }
      const response = $http.get(endpoint);
      return response;
    }

    return { list };
  },
];
