/**
 * @name userMaintenanceDWTModule
 * @description This is a module used for user maintenance of DWT. All the navigations
 *              belonging to user maintenance happen here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module(
    'userMaintenanceDwtModule',
    ['ui.router', 'ngStorage', 'ngResource',
      'userMaintenanceModule'])
  .config(['$stateProvider',
    function($stateProvider) {

      $stateProvider
        .state(
          'userMaintenanceForDWT',
          {
            params: {
              'selectedIndex': 2
            },
            data: {
              nameList: ['label_userMaintenance']
            },
            views: {
              'mainView': {
                templateUrl: 'features/modules/user-maintenance-dwt/user-maintenance-main/user-maintenance-dwt-header.html',
                // This for activating first tab
                controller:['$scope', '$state',
                  '$sessionStorage', function($scope, $state,
                    $sessionStorage) {
                    $scope.prodType = $sessionStorage.productType;
                    // Added for preventing redirection
                    // from Action Home page
                    if ('userMaintenanceForDWT' === $state.current.name) {
                      if ($scope.prodType === 'DWT') {
                        $state
                          .go('userMaintenanceForDWT.agentGroupDwt');
                      } else if ($scope.prodType === 'Shareholder') {
                        $state
                          .go(
                            'userMaintenanceForDWT.agentUserListShareholder',
                            {
                              groupId: 1
                            });
                      }
                    }
                  }]
              }
            }
          })
        .state(
          'userMaintenanceForDWT.agentGroupDwt',
          {
            url: '/agentGroupDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/agent-group/agent-group-dwt.html',
                controller: 'AgentGroupDwtController',
                controllerAs: 'agentGroupDwt'
              }
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_agent_group']
            }
          })
        .state(
          'userMaintenanceForDWT.editagentGroupDwt',
          {
            url: '/editagentGroupDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/edit-user-group/edit-agent-group-dwt.html',
                controller: 'EditAgentGroupDwtController',
                controllerAs: 'userGroupDetailsDwt'
              }
            },
            params: {
              'productCode': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_edit_agent_group']
            }
          })
        .state(
          'userMaintenanceForDWT.agentUserListDwt',
          {
            url: '/agentUserListDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user-list/view-agent-user-list-dwt.html',
                controller: 'AgentUserListDwtController',
                controllerAs: 'viewUserListDwt'
              }
            },
            params: {
              'groupId': null,
              'successMsg': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_agent_group',
                'label_view_agent_group_users']
            }
          })

        .state(
          'userMaintenanceForDWT.agentUserListShareholder',
          {
            url: '/agentUserListShareholder',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user-list/view-agent-user-list-dwt.html',
                controller: 'AgentUserListDwtController',
                controllerAs: 'viewUserListDwt'
              }
            },
            params: {
              'groupId': 1,
              'successMsg': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_agent_group_users']
            }
          })

        .state(
          'userMaintenanceForDWT.addUserDwt',
          {
            url: '/addUserDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/add-user/add-agent-dwt.html',
                controller: 'AddAgentDwtController',
                controllerAs: 'addUserDataDwt'
              }
            },
            params: {
              'groupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_agent_group', 'label_add_user']
            }
          })
        .state(
          'userMaintenanceForDWT.viewUserDwt',
          {
            url: '/viewUserDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user/view-agent-dwt.html',
                controller: 'ViewAgentDwtController',
                controllerAs: 'viewUserDetailsDwt'
              }
            },
            params: {
              'userId': null,
              'userType': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_agent_group',
                'label_view_agent_details']
            }
          })
        .state(
          'userMaintenanceForDWT.editUserDwt',
          {
            url: '/editUserDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/edit-user/edit-agent-dwt.html',
                controller: 'EditAgentDwtController',
                controllerAs: 'editUserDetailsDwt'
              }
            },
            params: {
              'userId': null,
              'userType': null,
              'groupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_agent_group',
                'label_edit_agent_details']
            }
          })
        .state(
          'userMaintenanceForDWT.issuerGroupDwt',
          {
            url: '/issuerGroupDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user-group/view-issuer-groups-dwt.html',
                controller: 'IssuerGroupDwtController',
                controllerAs: 'viewUserGroupDwt'
              }
            },
            params: {
              'successMsg': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group']
            }
          })
        .state(
          'userMaintenanceForDWT.addIssuerGroupDwt',
          {
            url: '/addIssuerGroupDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/add-user-group/add-issuer-group-dwt.html',
                controller: 'AddIssuerGroupDwtController',
                controllerAs: 'addUserGroupDataDwt'
              }
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_add_issuer_group']
            }
          })
        .state(
          'userMaintenanceForDWT.editIssuerGroupDwt',
          {
            url: '/editIssuerGroupDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/edit-user-group/edit-issuer-group-dwt.html',
                controller: 'EditIssuerGroupDwtController',
                controllerAs: 'editUserGroupDetailsDwt'
              }
            },
            params: {
              'groupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_edit_issuer_details']
            }
          })
        .state(
          'userMaintenanceForDWT.viewIssuerGroupDetailsDwt',
          {
            url: '/viewIssuerGroupDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user-group/view-issuer-group-details-dwt.html',
                controller: 'ViewIssuerGroupDetailsDwtController',
                controllerAs: 'userGroupDetailsDwt'
              }
            },
            params: {
              'groupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_view_issuer_details']
            }
          })
        .state(
          'userMaintenanceForDWT.issuerUserListDwt',
          {
            url: '/issuerUserListDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user-list/view-issuer-user-list-dwt.html',
                controller: 'IssuerUserListDwtController'
              }
            },
            params: {
              'groupId': null,
              'status': null,
              'successMsg': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_view_issuer_user']
            }
          })
        .state(
          'userMaintenanceForDWT.viewIssuerDwt',
          {
            url: '/viewIssuerDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user/view-user-dwt.html',
                controller: 'ViewIssuerDwtController',
                controllerAs: 'viewUserDetailsDwt'
              }
            },
            params: {
              'userId': null,
              'userType': null,
              'groupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_view_issuer_details']
            }
          })
        .state(
          'userMaintenanceForDWT.editIssuerDwt',
          {
            url: '/editIssuerDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/edit-user/edit-issuer-dwt.html',
                controller: 'EditIssuerDwtController',
                controllerAs: 'editUserDetailsDwt'
              }
            },
            params: {
              'userId': null,
              'userType': null,
              'groupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_edit_issuer_details']
            }
          })
        .state(
          'userMaintenanceForDWT.addIssuerDwt',
          {
            url: '/addIssuerDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/add-user/add-issuer-dwt.html',
                controller: 'AddIssuerDwtController',
                controllerAs: 'addUserDataDwt'
              }
            },
            params: {
              'userGroupId': null,
              'breadCrumbDetails': []
            },
            data: {
              displayName: 'Add issuer',
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_add_issuer']
            }
          })
        .state(
          'userMaintenanceForDWT.intermediaryGroupDwt',
          {
            url: '/intermediaryGroupDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user-group/view-intermediary-groups-dwt.html',
                controller: 'IntermediaryGroupDwtController',
                controllerAs: 'viewUserGroupDwt'
              }
            },
            params: {
              'successMsg': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_intermediary_group']
            }
          })

        .state(
          'userMaintenanceForDWT.viewAllUsersOfDwt',
          {
            url: '/viewAllUsersOfDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user-list/view-all-users-dwt.html',
                controller: 'ViewAllUsersDwtController'
              }
            },
            params: {
              'successMsg': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_all_users']
            }
          })
        .state(
          'userMaintenanceForDWT.viewIntermediaryGroupDetailsDwt',
          {
            url: '/viewIntermediaryGroupDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user-group/view-intermediary-group-details-dwt.html',
                controller: 'ViewIntermediaryGroupDetailsDwtController',
                controllerAs: 'viewIntermediaryGroupDetailsDwt'
              }
            },
            params: {
              'groupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_intermediary_group',
                'label_view_intermediary_details']
            }
          })
        .state(
          'userMaintenanceForDWT.editIntermediaryGroupDwt',
          {
            url: '/editIntermediaryGroupDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/edit-user-group/edit-intermediary-group-dwt.html',
                controller: 'EditIntermediaryGroupDwtController',
                controllerAs: 'editUserGroupDetailsDwt'
              }
            },
            params: {
              'groupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_intermediary_group',
                'label_edit_intermediary_details']
            }
          })
        .state(
          'userMaintenanceForDWT.addIntermediaryDwt',
          {
            url: '/addIintermediaryDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/add-user/add-intermediary-dwt.html',
                controller: 'AddIntermediaryDwtController',
                controllerAs: 'addUserDataDwt'
              }
            },
            params: {
              'userGroupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_intermediary_group',
                'label_add_intermediary']
            }
          })
        .state(
          'userMaintenanceForDWT.editIntermediaryDwt',
          {
            url: '/editInteremediaryDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/edit-user/edit-intermediary-dwt.html',
                controller: 'EditIntermediaryDwtController',
                controllerAs: 'editUserDetailsDwt'
              }
            },
            params: {
              'userId': null,
              'userType': null,
              'groupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_intermediary_group',
                'label_edit_intermediary_details']
            }
          })
        .state(
          'userMaintenanceForDWT.addIntermediaryGroupDwt',
          {
            url: '/addIntermediaryGroupDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/add-user-group/add-intermediary-group-dwt.html',
                controller: 'AddIntermediaryGroupDwtController',
                controllerAs: 'addUserGroupDataDwt'
              }
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_intermediary_group',
                'label_add_intermediary_group']
            }
          })
        .state(
          'userMaintenanceForDWT.intermediaryUserListDwt',
          {
            url: '/intermediaryrUserListDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user-list/view-intermediary-user-list-dwt.html',
                controller: 'IntermediaryUserListDwtController'
              }
            },
            params: {
              'groupId': null,
              'status': null,
              'successMsg': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_intermediary_group',
                'label_view_intermediary_user']
            }
          })
        .state(
          'userMaintenanceForDWT.viewIntermediaryDwt',
          {
            url: '/viewIntermediaryDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user/view-user-dwt.html',
                controller: 'ViewIntermediaryDwtController',
                controllerAs: 'viewUserDetailsDwt'
              }
            },
            params: {
              'groupId': null,
              'userId': null,
              'userType': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_intermediary_group',
                'label_view_intermediary_details']
            }
          })
        .state(
          'userMaintenanceForDWT.peShareholderListDwt',
          {
            url: '/peShareholderListDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user-list/view-shareholder-list-dwt.html',
                controller: 'ShareholderListDwtController'
              }
            },
            params: {
              'groupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_view_shareholder_list']
            }
          })
        .state(
          'userMaintenanceForDWT.addPeShareholderDwt',
          {
            url: '/addPeShareholderDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/add-user/add-pe-shareholder-dwt.html',
                controller: 'AddPeShareholderDwtController',
                controllerAs: 'addUserDataDwt'
              }
            },
            params: {
              'userGroupId': null,
              'shareclassId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_add_shareholder']
            }
          })
        .state(
          'userMaintenanceForDWT.viewPeShareholderDwt',
          {
            url: '/viewPeShareholderDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user/view-pe-shareholder-dwt.html',
                controller: 'viewPeShareholderDwtController',
                controllerAs: 'viewUserDataDwt'
              }
            },
            params: {
              'userGroupId': null,
              'shareholderId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_view_shareholder_details']
            }
          })
        .state(
          'userMaintenanceForDWT.editPeShareholderDwt',
          {
            url: '/editPeShareholderDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/edit-user/edit-pe-shareholder-dwt.html',
                controller: 'EditPeShareholderDwtController',
                controllerAs: 'editUserDataDwt'
              }
            },
            params: {
              'userGroupId': null,
              'shareholderId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_edit_shareholder']
            }
          })
        .state(
          'userMaintenanceForDWT.peShareclassListDwt',
          {
            url: '/peShareclassListDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/view-user-list/view-shareclass-list-dwt.html',
                controller: 'ShareclassListDwtController'
              }
            },
            params: {
              'groupId': null,
              'status': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_view_shareclass_list']
            }
          })
        .state(
          'userMaintenanceForDWT.addPeShareclassDwt',
          {
            url: '/addPeShareclassDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/add-user/add-pe-shareclass-dwt.html',
                controller: 'AddPeShareclassDwtController',
                controllerAs: 'addUserDataDwt'
              }
            },
            params: {
              'groupId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_add_shareclass']
            }
          })
        .state(
          'userMaintenanceForDWT.editPeShareclassDwt',
          {
            url: '/editPeShareclassDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/edit-user/edit-pe-shareclass-dwt.html',
                controller: 'EditPeShareclassDwtController',
                controllerAs: 'editUserDataDwt'
              }
            },
            params: {
              'groupId': null,
              'shareclassId': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_edit_shareclass']
            }
          })
        .state(
          'userMaintenanceForDWT.addPermissionDwt',
          {
            url: '/addPermissionDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/intermediary-and-issuer-actions/add-permission-intermediary-dwt.html',
                controller: 'AddPermissionDwtController',
                controllerAs: 'permissions'
              }
            },
            params: {
              'groupId': null,
              'productCode': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_intermediary_group',
                'label_add_permissions']
            }
          })
        .state(
          'userMaintenanceForDWT.addPermissionIssuerDwt',
          {
            url: '/addPermissionIssuerDwt',
            views: {
              'userMaintenanceDwtDeatils': {
                templateUrl: 'features/modules/user-maintenance-dwt/intermediary-and-issuer-actions/add-permission-issuer-dwt.html',
                controller: 'AddPermissionIssuerDwtController',
                controllerAs: 'issuerPermissions'
              }
            },
            params: {
              'groupId': null,
              'productCode': null,
              'breadCrumbDetails': []
            },
            data: {
              nameList: ['label_userMaintenance',
                'label_view_issuer_group',
                'label_add_permissions']
            }
          });
    }]);
