const WORKSTATION_MAP = {
  ag: 'agent',
  is: 'issuer',
  in: 'intermediary',
  vc: 'vote-collector',
  sp: 'shareplan',
  spp: 'shareplan-participant',
  sh: 'shareholder',
};

/**
 *
 * @param {string} userType 'AG', 'IS', 'IN', 'VC', etc.
 * @returns 'agent', 'issuer', 'intermediary', etc.
 */
export function userTypeToPathSegment(userType) {
  return WORKSTATION_MAP[userType.toLowerCase()];
}
