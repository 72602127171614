import gmVcVotingResults from './gm-vote-collector-view/voting-results/voting-results.component';

import gmVcVotingConfirmations from './gm-vote-collector-view/voting-confirmations/voting-confirmations.component';
import gmVcConfirmedVotes from './gm-vote-collector-view/voting-confirmations/confirmed-votes/confirmed-votes.component';
import voteConfirmationsLoadingDialog from './gm-vote-collector-view/voting-confirmations/vote-confirmations-loading-dialog/vote-confirmations-loading-dialog.component';

import gmVoteCollectorView from './gm-vote-collector-view/gm-vote-collector-view.component';

import voteConfirmationTable from './gm-vote-collector-view/voting-confirmations/confirmed-votes/vote-confirmations-table.component';

import registrationListNotificationView from './registrations-tab/registration-list-notification-view/registration-list-notification-view.component';

/**
 * @name generalMeetingMainModule
 * @description generalMeetingMainModule AgentModule used for Agent WorkStation
 */
angular
  .module('generalMeetingMainModule', [
    'ui.router',
    'ngStorage',
    'commonModule',
    'ngResource',
    'ui.bootstrap',
    'ngAnimate',
    'ngSanitize',
    'ReorderModule',
    'userMaintenanceModule',
    'UIControlModule',
    'gmComponentsModule',
    'richTextEditorModule',
    'gmModal',
    'myAccountProfile',
    'MenuModule',
    'ui.bootstrap.datetimepicker',
    'angular.filter',
  ])
  .component({
    gmVcVotingResults,
    gmVcVotingConfirmations,
    gmVcConfirmedVotes,
    voteConfirmationsLoadingDialog,
    gmVoteCollectorView,
    voteConfirmationTable,
    registrationListNotificationView,
  })
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('agentHome', {
          url: '/agentHome',
          params: {
            selectedIndex: 0,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/action-home-module/action-home.html',
              controller: 'ActionHomeController',
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('generalDetailsView', {
          url: '/generalDetailsView',
          params: {
            selectedIndex: 1,
            meetingId: null,
            status: null,
            gmDateTimeMilli: null,
            gmDateTime: null,
            flag: null,
            issuerId: null,
            sendRegViEuroclear: null,
            fromUrl: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/general-meeting-module/gm-general-details.html',
              controller: 'GeneralDetailsController',
            },
          },
        })
        .state('generalDetailsView.generalDetails', {
          url: '/generalDetailsTab',
          views: {
            generalMeetingDetails: {
              templateUrl:
                'features/modules/general-meeting-module/gm-general-details-tab.html',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_generalDetails'],
          },
        })
        .state('generalDetailsView.registrations', {
          url: '/registrations',
          views: {
            generalMeetingDetails: {
              templateUrl:
                'features/modules/general-meeting-module/registrations-tab/gm-registrations-tab.html',
              controller: 'RegistrationTabController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_registrations'],
          },
        })
        .state('generalDetailsView.voteCollector', {
          url: '/voteCollector',
          views: {
            generalMeetingDetails: {
              component: 'gmVoteCollectorView',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'userMaint_voteCollector_label',
            ],
          },
        })
        .state('generalDetailsView.sendEmailEuroclear', {
          url: '/sendEmailEuroclear',
          views: {
            generalMeetingDetails: {
              templateUrl:
                'features/modules/send-email-euroclear/send-email-euroclear.html',
              controller: 'EuroclearController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_sendEmailEuro'],
          },
        })
        .state('createGM', {
          url: '/createGM',
          params: {
            selectedIndex: 1,
            meetingStatus: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/create-gm-module/create-general-meeting.html',
              controller: 'createGmDatePickerCtrl',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_createGenMeeting'],
          },
        })
        .state('editGM', {
          url: '/editGM',
          params: {
            selectedIndex: 1,
            issuerId: null,
            meetingStatus: null,
            tabId: null,
            gmStatus: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/create-gm-module/create-general-meeting.html',
              controller: 'createGmDatePickerCtrl',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_editGenMeeting'],
          },
        })
        .state('agentMyAccount', {
          url: '/agentMyAccount',
          params: {
            selectedIndex: 2,
          },
          views: {
            mainView: {
              templateUrl: 'html/my-account-agent.html',
              controller: 'MyAccountController',
            },
          },
          data: {
            nameList: ['item2'],
          },
        });
    },
  ]);
