import { padEncodedKey } from '@/lib/encodedKey';

const GET_PREFS_ENDPOINT = '/v1/issuerpreference/';

export default [
  '$resource',
  '$sessionStorage',
  '$http',
  function ($resource, $sessionStorage, $http) {
    async function getCsdPreferenceForIssuer(issuerId) {
      if (issuerId.length < 16) {
        issuerId = padEncodedKey(issuerId);
      }
      try {
        const response = await $http.get(
          `${GET_PREFS_ENDPOINT}${issuerId}/CSD_PREFERENCE`
        );
        return response.data.value;
      } catch (err) {
        // Endpoint returns a 404 if the issuers simply doesn't have a preference,
        // this doesn't need to be considered an error
        if (err.status !== 404) {
          console.error(err);
        }
        return null;
      }
    }

    function policy(issuerId) {
      var language = $sessionStorage.workstationData.selectedLang;
      // Condition check for language
      switch (language) {
        case 'EN':
          language = 'EN';
          break;
        case 'NL':
          language = 'NL';
          break;
        case 'FR':
          language = 'FR';
          break;
        default:
          language = 'EN';
          break;
      }
      var policyKey = 'POLICY_URL_' + language;
      return $resource(
        'v1/issuerpreference/' + issuerId + '/' + policyKey,
        {},
        {
          get: {
            method: 'GET',
          },
        }
      );
    }

    return { getCsdPreferenceForIssuer, policy };
  },
];
