/**
 * Takes a encoded key without "salt", and transforms it to one with salt.
 * For use in endpoints where a key is encoded key with salt is expected
 *
 * @param {string} keyWoSalt - something like 'MTg=='
 * @returns {string} something like '0liCjogZGfgZxr_YMTA2Mg=='
 */
export function padEncodedKey(keyWoSalt) {
  return `${'0'.repeat(16)}${keyWoSalt}`;
}

/**
 * Takes a key with "salt", and transform it to one wthout salt
 *
 * @param {string} keyWithSalt - something like '0liCjogZGfgZxr_YMTA2Mg=='
 * @returns {string} something like 'MTg=='
 */
export function trimEncodedKey(keyWithSalt) {
  return keyWithSalt.substring(16);
}
