/**
 * @name generalMeetingIntermediaryModule
 * @description This is a module used for general meeting related modules
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('intermediaryModule', ['ui.router', 'ngStorage', 'ngResource'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('intermediaryGeneralMeeting', {
          url: '/intermediary/generalMeeting',
          params: {
            selectedIndex: 1,
          },
          data: {
            nameList: ['label_generalMeeting1'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/intermediary-module/general-meeting/general-meeting-intermediary.html',
              controller: 'intermediaryGeneralMeetingCtrl',
            },
          },
        })
        .state('shareholderEmail', {
          url: '/shareholderEmail',
          params: {
            selectedIndex: 1,
            meetingId: null,
            issuerName: null,
            gmDateTime: null,
            gmDateTimeStamp: null,
            meetingType: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/intermediary-module/shareholder-email/shareholder-email.html',
              controller: 'ShareholderEmailController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_shareholder_email'],
          },
        })
        .state('intermediaryCreateExtensionRequest', {
          url: '/intermediaryCreateExtensionRequest',
          params: {
            selectedIndex: 1,
            intermediaryDeadlineDateStr: null,
            gmName: null,
            gmDateTime: null,
            generalMeetingId: null,
            extReqRejected: null,
            issuerId: null,
            breadCrumbDetails: [],
            shareholderName: null,
            position: null,
            reason: null,
            extensionRequestId: null,
            issuerName: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/intermediary-module/extension-request/create-extension-request.html',
              controller: 'CreateExtensionRequestController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_create_extension_request',
            ],
          },
        })
        .state('createIntermediaryExtensionRequest', {
          url: '/createIntermediaryExtensionRequest',
          params: {
            selectedIndex: 1,
            issuerId: null,
            gmName: null,
            generalMeetingId: null,
            gmDateTime: null,
            userType: null,
            extReqRejected: null,
            intermediaryDeadlineDateStr: null,
            gmStatus: null,
            issuerName: null,
            allExtnReqRejected: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/intermediary-module/extension-request/view-intermediary-extension-requests.html',
              controller: 'ViewIntermediaryExtController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_extReq'],
          },
        })
        .state('virtualParticipationView', {
          url: '/virtualParticipationView',
          params: {
            selectedIndex: 1,
            gmId: null,
            issuerId: null,
            issuerName: null,
            generalMeetingDateMillis: null,
            intermediaryDeadlineDateMillis: null,
            intermediaryAccessDateMillis: null,
            timeZone: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/intermediary-module/virtual-participation/virtual-participation-request.html',
              controller: 'virtualParticipationCtrl',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_virtual_participation'],
          },
          resolve: {
            extReqResults: [
              'ExtensionRequestService',
              '$stateParams',
              '$sessionStorage',
              function (
                ExtensionRequestService,
                $stateParams,
                $sessionStorage
              ) {
                return ExtensionRequestService.getExtensionReqStatus(
                  $stateParams.gmId,
                  $sessionStorage.groupId
                )
                  .query()
                  .$promise.then(
                    function (data) {
                      return data;
                    },
                    function (error) {
                      return error;
                    }
                  );
              },
            ],
          },
        })
        .state('votingInstructionsIntermediaryLinkedMeeting', {
          url: '/votingInstructionsIntermediary',
          params: {
            selectedIndex: 1,
            linkedMeetingData: [],
            issuerName: null,
            meetingId: null,
            issuerId: null,
            votingInstDetails: null,
            isLinkedMeeting: false,
            selectedSecurityClass: null,
            shareholderRegistrationDetailVO: null,
            profileListData: null,
            securityAccountNumber: null,
            shareHolderVO: null,
            shTitleRequired: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/voting-instruction-intermediary.html',
              controller: 'VotingInstructionIntermediaryController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_voting_instruction',
              'label_newVote',
            ],
          },
        })
        .state('attendanceReqIntermediaryLinkedMeeting', {
          url: '/attendanceRequest',
          params: {
            selectedIndex: 1,
            attendanceDataDeatils: null,
            linkedMeetingData: [],
            issuerName: null,
            gmId: null,
            issuerId: null,
            isLinkedMeeting: false,
            selectedShareClass: null,
            shareholderRegistrationDetailVO: null,
            shareHolderVOList: [],
            profileListData: null,
            shTitleRequired: null,
            attendProxyBy: null,
            selectedProxyType: null,
            selectedProxyTitle: null,
            selectedProxyCountry: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/attendance-request.html',
              controller: 'AttendanceRequestController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_voting_instruction',
              'label_newVote',
            ],
          },
        });
    },
  ]);
