/**
 * @name userMaintenanceModule
 * @description This is a module used for user maintenance. All the navigations
 *              belonging to user maintenance happen here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('userMaintenanceModule', [
    'ui.router',
    'ngStorage',
    'commonModule',
    'ngResource',
    'gmModal',
  ])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('userMaintenance', {
          params: {
            selectedIndex: 1,
          },
          data: {
            nameList: ['label_userMaintenance'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/user-maintenance/user-maintenance-main/user-maintenance-header.html',
              controller: 'UserMaintenanceController',
            },
          },
        })
        .state('userMaintenance.agentGroup', {
          url: '/agentGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/agent-group/agent-group.html',
              controller: 'AgentGroupController',
              controllerAs: 'agentGroup',
            },
          },
          data: {
            nameList: ['label_userMaintenance', 'label_view_agent_group'],
          },
          resolve: {
            agentGroup: [
              'userGroupService',
              function (userGroupService) {
                return userGroupService
                  .getAgentGroup('1', 'GM', 'EN')
                  .query()
                  .$promise.then(
                    function (data) {
                      return data;
                    },
                    function (error) {
                      return error;
                    }
                  );
              },
            ],
          },
        })
        .state('userMaintenance.editAgentGroup', {
          url: '/editAgentGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/edit-user-group/edit-user-group.html',
              controller: 'EditUserGroupController',
              controllerAs: 'userGroupDetails',
            },
          },
          params: {
            breadCrumbDetails: [],
          },
          data: {
            nameList: ['label_userMaintenance', 'label_edit_agent_group'],
          },
          resolve: {
            userGroupDetails: [
              'userGroupService',
              function (userGroupService) {
                return userGroupService
                  .getAgentGroup('1', 'GM', 'EN')
                  .query()
                  .$promise.then(
                    function (data) {
                      return data;
                    },
                    function (error) {
                      return error;
                    }
                  );
              },
            ],
          },
        })
        .state('userMaintenance.editIssuerGroup', {
          url: '/editIssuerGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/edit-user-group/edit-user-group.html',
              controller: 'EditIssuerGroupController',
              controllerAs: 'editUserGroupDetails',
            },
          },
          params: {
            groupId: null,
            pageNo: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_issuer_group',
              'label_edit_issuer_details',
            ],
          },
        })
        .state('userMaintenance.editIntermediaryGroup', {
          url: '/editIntermediaryGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/edit-user-group/edit-intermediary-group.html',
              controller: 'EditIntermediaryGroupController',
              controllerAs: 'editUserGroupDetails',
            },
          },
          params: {
            groupId: null,
            pageNo: null,
            groupName: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_intermediary_group',
              'label_edit_intermediary_details',
            ],
          },
        })
        .state('userMaintenance.editProxyIntermediaryGroup', {
          url: '/editProxyIntermediaryGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/edit-user-group/edit-intermediary-group.html',
              controller: 'EditProxyGroupController',
              controllerAs: 'editUserGroupDetails',
            },
          },
          params: {
            groupId: null,
            pageNo: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_proxy_intermediary_group',
              'label_edit_proxy_intermediary_details',
            ],
          },
        })
        .state('userMaintenance.editVCGroup', {
          url: '/editVCGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/edit-user-group/edit-user-group.html',
              controller: 'EditVCGroupController',
              controllerAs: 'editUserGroupDetails',
            },
          },
          params: {
            groupId: null,
            pageNo: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_vote_collector_group',
              'label_edit_votecollector_details',
            ],
          },
        })
        .state('userMaintenance.agentUserList', {
          url: '/agentUserList',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user-list/view-user-list.html',
              controller: 'UserListController',
              controllerAs: 'viewUserList',
            },
          },
          params: {
            groupId: null,
            page: null,
            userType: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_agent_group',
              'label_agent_group',
            ],
          },
        })
        .state('userMaintenance.addUser', {
          url: '/addUser',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/add-user/add-user.html',
              controller: 'AddUserController',
              controllerAs: 'addUserData',
            },
          },
          params: {
            groupId: null,
            userType: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_agent_group',
              'label_agent_group',
              'label_addAgentUser',
            ],
          },
        })
        .state('userMaintenance.addIssuer', {
          url: '/addIssuer',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/add-user/add-user.html',
              controller: 'AddUserController',
              controllerAs: 'addUserData',
            },
          },
          params: {
            userGroupId: null,
            groupStatus: null,
            userType: null,
            breadCrumbDetails: [],
          },
          data: {
            displayName: 'Add issuer',
            nameList: [
              'label_userMaintenance',
              'label_view_issuer_group',
              'label_viewIssuers',
              'label_addIssuerUser',
            ],
          },
        })
        .state('userMaintenance.addIntermediary', {
          url: '/addIintermediary',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/add-user/add-user.html',
              controller: 'AddUserController',
              controllerAs: 'addUserData',
            },
          },
          params: {
            userGroupId: null,
            groupStatus: null,
            userType: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_intermediary_group',
              'label_viewIntermediaries',
              'label_addIntermediaryUser',
            ],
          },
        })
        .state('userMaintenance.addProxy', {
          url: '/addProxy',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/add-user/add-user.html',
              controller: 'AddUserController',
              controllerAs: 'addUserData',
            },
          },
          params: {
            userGroupId: null,
            groupStatus: null,
            userType: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_proxy_intermediary_group',
              'label_viewProxies',
              'label_addProxyUser',
            ],
          },
        })
        .state('userMaintenance.addVC', {
          url: '/addVC',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/add-user/add-user.html',
              controller: 'AddUserController',
              controllerAs: 'addUserData',
            },
          },
          params: {
            userGroupId: null,
            groupStatus: null,
            userType: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_vote_collector_group',
              'label_viewVCs',
              'label_addVCUser',
            ],
          },
        })
        .state('userMaintenance.viewUser', {
          url: '/viewUser',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user/view-user.html',
              controller: 'ViewUserController',
              controllerAs: 'viewUserDetails',
            },
          },
          params: {
            userId: null,
            userType: null,
            groupId: null,
            productCode: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_agent_group',
              'label_agent_group',
              'label_viewAgentUser',
            ],
          },
        })
        .state('userMaintenance.editUser', {
          url: '/editUser',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/edit-user/edit-user.html',
              controller: 'EditUserController',
              controllerAs: 'editUserDetails',
            },
          },
          params: {
            userId: null,
            userType: null,
            groupId: null,
            page: null,
            groupName: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_agent_group',
              'label_agent_group',
              'label_edit_agent_details',
            ],
          },
        })
        .state('userMaintenance.editIssuer', {
          url: '/editIssuer',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/edit-user/edit-user.html',
              controller: 'EditUserController',
              controllerAs: 'editUserDetails',
            },
          },
          params: {
            userId: null,
            userType: null,
            groupId: null,
            status: null,
            page: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_issuer_group',
              'label_viewIssuers',
              'label_edit_issuer_details',
            ],
          },
        })
        .state('userMaintenance.editIntermediary', {
          url: '/editInteremediary',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/edit-user/edit-user.html',
              controller: 'EditUserController',
              controllerAs: 'editUserDetails',
            },
          },
          params: {
            userId: null,
            userType: null,
            groupId: null,
            status: null,
            page: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_intermediary_group',
              'label_viewIntermediaries',
              'label_edit_intermediary_details',
            ],
          },
        })
        .state('userMaintenance.editProxy', {
          url: '/editProxy',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/edit-user/edit-user.html',
              controller: 'EditUserController',
              controllerAs: 'editUserDetails',
            },
          },
          params: {
            userId: null,
            userType: null,
            groupId: null,
            status: null,
            page: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_proxy_intermediary_group',
              'label_viewProxies',
              'label_edit_proxy_intermediary_details',
            ],
          },
        })
        .state('userMaintenance.editVC', {
          url: '/editVC',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/edit-user/edit-user.html',
              controller: 'EditUserController',
              controllerAs: 'editUserDetails',
            },
          },
          params: {
            userId: null,
            userType: null,
            groupId: null,
            status: null,
            page: null,
            redirectedFrom: null,
            gmId: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_vote_collector_group',
              'label_viewVCs',
              'label_edit_votecollector_details',
            ],
          },
        })
        .state('userMaintenance.issuerGroup', {
          url: '/issuerGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user-group/view-groups.html',
              controller: 'IssuerGroupController',
              controllerAs: 'viewUserGroup',
            },
          },
          params: {
            pageNo: null,
          },
          data: {
            nameList: ['label_userMaintenance', 'label_view_issuer_group'],
          },
        })
        .state('userMaintenance.voteCollectorGroup', {
          url: '/voteCollectorGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user-group/view-groups.html',
              controller: 'VoteCollectorGroupController',
              controllerAs: 'viewUserGroup',
            },
          },
          params: {
            pageNo: null,
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_vote_collector_group',
            ],
          },
        })
        .state('userMaintenance.intermediaryGroup', {
          url: '/intermediaryGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user-group/view-intermediary-groups.html',
              controller: 'IntermediaryGroupController',
              controllerAs: 'viewUserGroup',
            },
          },
          params: {
            pageNo: null,
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_intermediary_group',
            ],
          },
        })
        .state('userMaintenance.proxyIntermediaryGroup', {
          url: '/proxyIntermediaryGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user-group/view-intermediary-groups.html',
              controller: 'ProxyGroupController',
              controllerAs: 'viewUserGroup',
            },
          },
          params: {
            pageNo: null,
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_proxy_intermediary_group',
            ],
          },
        })
        .state('userMaintenance.addIssuerGroup', {
          url: '/addIssuerGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/add-user-group/add-user-group.html',
              controller: 'AddIssuerGroupController',
              controllerAs: 'addUserGroupData',
            },
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_issuer_group',
              'label_add_issuer_group',
            ],
          },
        })
        .state('userMaintenance.addVCGroup', {
          url: '/addVCGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/add-user-group/add-vc-group.html',
              controller: 'AddVCGroupController',
              controllerAs: 'addUserGroupData',
            },
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_vote_collector_group',
              'label_add_vote_collector_group',
            ],
          },
        })
        .state('userMaintenance.addIntermediaryGroup', {
          url: '/addIntermediaryGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/add-user-group/add-intermediary-group.html',
              controller: 'AddIntermediaryGroupController',
              controllerAs: 'addUserGroupData',
            },
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_intermediary_group',
              'label_add_intermediary_group',
            ],
          },
        })
        .state('userMaintenance.addProxyGroup', {
          url: '/addProxyGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/add-user-group/add-intermediary-group.html',
              controller: 'AddProxyIntermediaryGroupController',
              controllerAs: 'addUserGroupData',
            },
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_proxy_intermediary_group',
              'label_add_proxy_intermediary_group',
            ],
          },
        })
        .state('userMaintenance.intermediaryUserList', {
          url: '/intermediaryrUserList',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user-list/view-user-list.html',
              controller: 'UserListController',
            },
          },
          params: {
            groupId: null,
            status: null,
            productCode: null,
            userType: null,
            page: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_intermediary_group',
              'label_viewIntermediaries',
            ],
          },
        })
        .state('userMaintenance.proxyIntermediaryUserList', {
          url: '/proxyIntermediaryrUserList',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user-list/view-user-list.html',
              controller: 'UserListController',
            },
          },
          params: {
            groupId: null,
            status: null,
            productCode: null,
            userType: null,
            page: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_proxy_intermediary_group',
              'label_viewProxies',
            ],
          },
        })
        .state('userMaintenance.issuerUserList', {
          url: '/issuerUserList',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user-list/view-user-list.html',
              controller: 'UserListController',
            },
          },
          params: {
            groupId: null,
            status: null,
            productCode: null,
            userType: null,
            page: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_issuer_group',
              'label_viewIssuers',
            ],
          },
        })
        .state('userMaintenance.vcUserList', {
          url: '/vcUserList',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user-list/view-user-list.html',
              controller: 'VCUserListController',
              controllerAs: 'viewUserList',
            },
          },
          params: {
            groupId: null,
            status: null,
            productCode: null,
            userType: null,
            page: null,
            gmId: null,
            redirectedFrom: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_vote_collector_group',
              'label_viewVCs',
            ],
          },
        })
        .state('userMaintenance.viewIssuer', {
          url: '/viewIssuer',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user/view-user.html',
              controller: 'ViewUserController',
              controllerAs: 'viewUserDetails',
            },
          },
          params: {
            userId: null,
            userType: null,
            groupId: null,
            productCode: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_issuer_group',
              'label_viewIssuers',
              'label_viewIssuerUser',
            ],
          },
        })
        .state('userMaintenance.viewIntermediary', {
          url: '/viewIntermediary',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user/view-user.html',
              controller: 'ViewUserController',
              controllerAs: 'viewUserDetails',
            },
          },
          params: {
            userId: null,
            userType: null,
            groupId: null,
            productCode: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_intermediary_group',
              'label_viewIntermediaries',
              'label_viewIntermediaryUser',
            ],
          },
        })
        .state('userMaintenance.viewProxy', {
          url: '/viewProxy',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user/view-user.html',
              controller: 'ViewUserController',
              controllerAs: 'viewUserDetails',
            },
          },
          params: {
            userId: null,
            userType: null,
            groupId: null,
            productCode: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_proxy_intermediary_group',
              'label_viewProxies',
              'label_viewProxyUser',
            ],
          },
        })
        .state('userMaintenance.viewVC', {
          url: '/viewVC',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user/view-user.html',
              controller: 'ViewUserController',
              controllerAs: 'viewUserDetails',
            },
          },
          params: {
            userId: null,
            userType: null,
            groupId: null,
            productCode: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_vote_collector_group',
              'label_viewVCs',
              'label_viewVCUser',
            ],
          },
        })
        .state('userMaintenance.addPermission', {
          url: '/addPermissions',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/intermediary-and-proxy-actions/add-permission.html',
              controller: 'AddPermissionController',
              controllerAs: 'permissions',
            },
          },
          params: {
            groupId: null,
            productCode: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_intermediary_group',
              'label_add_permissions',
            ],
          },
        })
        .state('userMaintenance.addPermissionProxy', {
          url: '/addPermissionProxy',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/intermediary-and-proxy-actions/add-permission.html',
              controller: 'AddPermissionController',
              controllerAs: 'permissions',
            },
          },
          params: {
            groupId: null,
            productCode: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_proxy_intermediary_group',
              'label_add_permissions',
            ],
          },
        })
        .state('userMaintenance.linkedIntermediaries', {
          url: '/linkedIntermediaries',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/intermediary-and-proxy-actions/link-intermediary.html',
              controller: 'LinkedIntermediariesController',
              controllerAs: 'linkedIntermediaries',
            },
          },
          params: {
            groupId: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_proxy_intermediary_group',
              'label_link_intermediaries',
            ],
          },
        })
        .state('userMaintenance.linkToIntermediary', {
          url: '/linkToIntermediary',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/intermediary-and-proxy-actions/add-intermediary-in-proxygroup.html',
              controller: 'AddIntermediaryProxyController',
            },
          },
          params: {
            proxyId: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: [
              'label_userMaintenance',
              'label_view_proxy_intermediary_group',
              'label_link_intermediaries',
              'label_link_intermediary',
            ],
          },
        })
        .state('userMaintenance.viewIssuerGroupDetails', {
          url: '/viewIssuerGroup',
          views: {
            userMaintenanceDeatils: {
              templateUrl:
                'features/modules/user-maintenance/view-user-group/view-issuer-group-details.html',
              controller: 'ViewIssuerGroupDetailsController',
              controllerAs: 'userGroupDetails',
            },
          },
          params: {
            groupId: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: ['label_userMaintenance', 'label_issuer'],
          },
        });
    },
  ]);
