/**
 * @name
 * @description This is a module used for user maintenance of DWT. All the navigations
 *              belonging to user maintenance happen here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('dwtAuditTrailModule', ['ui.router', 'ngStorage', 'ngResource'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('dwtAudit1', {
        url: '/dwtAudit1',
        params: {
          selectedIndex: 2,
        },
        views: {
          mainView: {
            templateUrl:
              'features/modules/dwt-module/tax-reclaim-audit-trail-module/dwt-admin-audit-trail.html',
            controller: 'TaxReclaimAuditController',
          },
        },
        data: {
          nameList: ['label_taxReclaim', 'label_auditAndTemplate'],
        },
      });
    },
  ]);
