const LIST_CSD_ENDPOINT = '/v2/public/csds';

export default [
  '$http',
  function ($http) {
    // CSD's will hardly ever change, so we can at least safely cache it locally
    let cachedCsds = null;

    async function list() {
      if (cachedCsds) {
        return cachedCsds;
      }
      const response = await $http.get(LIST_CSD_ENDPOINT);
      cachedCsds = response.data.filter((csd) => !csd.isDeleted);
      return cachedCsds;
    }

    return { list };
  },
];
