/**
 * @ngdoc module
 * @name actionHomeModule
 * @description This is a module used for action home
 * @requires $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'ngStorage', 'ngResource', 'gmModal'
 */
'use strict';
angular
  .module('actionHomeModule',
    ['ui.router', 'ngStorage', 'ngResource', 'gmModal'])
  .config(['$stateProvider',
    function($stateProvider) {
      $stateProvider
        .state(
          'actionHome',
          {
            url: '/home',
            params: {
              'selectedIndex': 0
            },
            data: {
              nameList:['label_home']
            },
            views: {
              'mainView': {
                templateUrl: 'features/modules/action-home-module/action-home.html',
                controller: 'ActionHomeController'
              }
            }
          }).state(
          'issuerActionHome',
          {
            url: '/issuerHome',
            params: {
              'selectedIndex': 0
            },
            data: {
              nameList:['label_home']
            },
            views: {
              'mainView': {
                templateUrl: 'features/modules/issuer-module/action-home/action-home.html'
              }
            }
          })
        .state(
          'inviteSharePlanHome',
          {
            url: '/inviteSharePlanHome',
            params: {
              'selectedIndex': 0,
              'meetingStatus': null,
              'issuerId': null,
              'gmDate':null,
              'issuerName':null,
              'documents':null,
              'emailBody': null,
              'emailSubject': null,
              'breadCrumbDetails':[]
            },
            views: {
              'mainView': {
                templateUrl: 'features/modules/invite-shareplan/invite-share-plan.html',
                controller: 'InviteSharePlanController'
              }
            },
            data: {
              nameList: ['label_home',
                'label_invite_share_plan']
            }
          });
    }]);
