module.exports = ['$http', '$sessionStorage', function($http, $sessionStorage) {

  /**
   * This function can be used when it is not surely known which workstation we're in. I.e., this function is the best
   * choice to use in components which are shared among workstations. (Saves the caller from putting the switch-like
   * checks everywhere)
   * @param {string} workStationCode - AG, IS, IN, etc. 
   */
  async function getMeForWorkstation(workstationCode) {
    if (workstationCode == 'AG') {
      return await getMeAsAgent();
    } else if (workstationCode == 'IS') {
      return await getMeAsIssuer();
    } else {
      // TODO: implement this (back-end) for the other workstations
      return {
        user: null,
        productCodes: []
      };
    }
  }

  /**
   * Function below return the currently logged in User and its associated workstation entity. Should get the data from 
   * /v2/{WS}/me
   * 
   * These functions are preferred over `getMeForWorkstation` when they are used in workstation-specific components.
   */
  async function getMeAsAgent() {
    return (await $http.get('/v2/agent/me')).data;
  }

  async function getMeAsIssuer() {
    return (await $http.get('/v2/issuer/me')).data;
  }

  return {
    getMeForWorkstation,
    getMeAsAgent,
    getMeAsIssuer
  };
}];