import styles from './voting-confirmations.module.scss';
import { userTypeToPathSegment } from '@/lib/workstationPathSegment';
import { trimEncodedKey } from '@/lib/encodedKey';

export default {
  bindings: {
    onShareClassChange: '&',
  },
  controller: [
    '$scope',
    'voteCollectorService',
    '$log',
    '$http',
    '$sessionStorage',
    '$filter',
    '$stateParams',
    'cbpToast',
    '$element',
    function (
      $scope,
      voteCollectorService,
      $log,
      $http,
      $sessionStorage,
      $filter,
      $stateParams,
      cbpToast,
      $element
    ) {
      this.$onInit = () => {
        this.styles = styles;
        this.selectedShareClassId = '';
        $scope.meetingId = $scope.meetingId || $stateParams.meetingId;
        this.userType = $sessionStorage.usertype || $scope.userType || 'VC';

        $scope.shareclassIdArrayForWhichVecButtonIsDisabled = [];
        this.dateOfSubmission = new Date();
        this.dateOfSubmission.setSeconds(0);
        this.dateOfSubmission.setMilliseconds(0);
        this.voteConfirmations = [];
        this.totalVoteConfirmations = 0;

        this.tabs = [
          {
            label: 'Submit confirmations',
            key: 'submit-confirmations',
          },
          {
            label: 'Confirmed votes',
            key: 'confirmed-votes',
          },
        ];

        this.loadShareclasses();
      };

      this.loadShareclasses = () => {
        $scope.userType = $sessionStorage.usertype;
        if ($stateParams.status === 'M') {
          $scope.shareClass = $sessionStorage.gmShareclassList;

          this.shareClassOptions = $scope.shareClass.reduce((acc, sc) => {
            acc[sc.shareClassId] = sc.shareClassName;
            return acc;
          }, {});
        } else {
          if ($scope.userType === 'VC') {
            $scope.meetingId = $stateParams.data.meetingId;
            $scope.agendaItems = $stateParams.data.agendaItems;
            $scope.issuerId = $stateParams.data.issuerId;
          }

          voteCollectorService
            .getVCShareclassList(
              $scope.meetingId,
              $scope.userType,
              $scope.languageCode
            )
            .then(async (response) => {
              $scope.shareClass = response.data;
              if ($scope.shareClass.length > 0) {
                $scope.selectedShareClass = $scope.shareClass[0].shareClassId;
              }
              if ($scope.shareClass.length === 1) {
                this.selectedShareClassId = $scope.shareClass[0].shareClassId;
                const response = await this.getConfirmedVotingInstructions();
                if (response.totalElements > 0) {
                  this.voteConfirmations = response.list;
                  this.totalVoteConfirmations = response.totalElements;
                  this.selectedTab = 'confirmed-votes';
                  $scope.$apply();
                } else {
                  this.selectedTab = 'submit-confirmations';
                }
                this.loadEmailTemplates();
              }
              this.shareClassOptions = $scope.shareClass.reduce((acc, sc) => {
                acc[sc.shareClassId] = sc.shareClassName;
                return acc;
              }, {});
            });
        }
      };

      $scope.intermediaryEmailContent = {};
      $scope.shareholderEmailContent = {};
      if ($sessionStorage.gmLang) {
        $scope.languageCode = $sessionStorage.gmLang;
      } else {
        $scope.languageCode = 'EN';
      }
      if ($sessionStorage.status) {
        $scope.gmStatusforVC = $sessionStorage.status;
      } else {
        $scope.gmStatusforVC = 'M';
      }
      $scope.intermediaryEmailContent.emailBody = '';
      $scope.shareholderEmailContent.emailBody = '';
      $scope.selectedIndex = 1;

      this.handleShareClassChange = async (id, name) => {
        this.onShareClassChange({ shareClassId: id });
        this.selectedShareClassId = id;

        const response = await this.getConfirmedVotingInstructions();
        this.voteConfirmations = response.list;
        this.totalVoteConfirmations = response.totalElements;
        if (response.totalElements > 0) {
          this.selectedTab = 'confirmed-votes';
          $scope.$apply();
        } else {
          this.selectedTab = 'submit-confirmations';
        }
        this.loadEmailTemplates();
      };

      $scope.showModalOk = () => {
        const root = $element[0];
        const modal = root.querySelector('#vecModal_Ok');
        modal.openModal();
      };

      $scope.closeModalOk = () => {
        this.setAction = null;
        const root = $element[0];
        const modal = root.querySelector('#vecModal_Ok');
        modal.closeModal(new Event('click'));
      };

      $scope.showModalNone = () => {
        const root = $element[0];
        const modal = root.querySelector('#vecModal_None');
        modal.openModal();
      };

      $scope.closeModalNone = () => {
        this.setAction = null;
        const root = $element[0];
        const modal = root.querySelector('#vecModal_None');
        modal.closeModal(new Event('click'));
      };

      this.loadEmailTemplates = async () => {
        this.isLoadingTemplates = true;
        Promise.all([
          voteCollectorService.getVotingConfirmationExampleTemplate(
            userTypeToPathSegment(this.userType),
            $scope.meetingId,
            this.selectedShareClassId,
            'IN'
          ),
          voteCollectorService.getVotingConfirmationExampleTemplate(
            userTypeToPathSegment(this.userType),
            $scope.meetingId,
            this.selectedShareClassId,
            'SH'
          ),
        ])
          .then(([inResponse, shResponse]) => {
            $scope.intermediaryEmailContent = {
              emailSubject: inResponse.data.templates.EN.subject,
              emailBody: inResponse.data.templates.EN.body,
            };

            $scope.shareholderEmailContent = {
              emailSubject: shResponse.data.templates.EN.subject,
              emailBody: shResponse.data.templates.EN.body,
            };
          })
          .catch((err) => {
            cbpToast.show(
              'There was an error while fetching the templates',
              'negative'
            );
            $log.error(err);
          })
          .finally(() => {
            this.isLoadingTemplates = false;
            $scope.$apply();
          });
      };

      this.getConfirmedVotingInstructions = async () => {
        this.error = null;
        this.isFetching = true;
        try {
          return await this.listConfirmedVotes(
            this.userType,
            trimEncodedKey($scope.meetingId),
            trimEncodedKey(this.selectedShareClassId)
          );
        } catch (err) {
          this.error = err;
        } finally {
          this.isFetching = false;
          $scope.$apply();
        }
      };

      this.listConfirmedVotes = async (userType, meetingId, shareClassId) => {
        const { data } = await $http.get(
          `/v2/gm/${userTypeToPathSegment(
            userType
          )}/general-meetings/${meetingId}/share-classes/${shareClassId}/vote-confirmations`,
          {
            params: {
              pageNo: 1,
              pageSize: 20,
            },
          }
        );
        return data;
      };

      this.sendConfirmations = async () => {
        try {
          await $http.post(
            `/v2/gm/${userTypeToPathSegment(this.userType)}/general-meetings/${
              $scope.meetingId
            }/shareclasses/${
              this.selectedShareClassId
            }/vote-instruction-execution-confirmation`,
            {
              vcSubmissionDate: this.dateOfSubmission.toISOString(),
              intermediaryEmailSubject:
                $scope.intermediaryEmailContent.emailSubject,
              intermediaryEmailBody: $scope.intermediaryEmailContent.emailBody,
              shareholderEmailSubject:
                $scope.shareholderEmailContent.emailSubject,
              shareholderEmailBody: $scope.shareholderEmailContent.emailBody,
            }
          );
          return 'OK';
        } catch (err) {
          const errorMessage = err.data.errors[0].errorMessage;
          if (errorMessage.includes('No confirmations were created')) {
            return 'NONE';
          } else {
            return 'ERR';
          }
        }
      };

      this.sendVotingConfirmation = async () => {
        this.sendingVoteConfirmations = true;
        this.showVcLoadingDialog = true;
        try {
          const status = await this.sendConfirmations();
          $scope.shareclassIdArrayForWhichVecButtonIsDisabled.push(
            this.selectedShareClassId
          );
          if (status === 'ERR') {
            cbpToast.show(
              'Something went wrong while trying to generate the confirmations.',
              'negative'
            );
          } else if (status === 'NONE') {
            $scope.showModalNone();
          } else if (status === 'OK') {
            $scope.showModalOk();
          }
        } catch (error) {
          // For very unexpected errors
          cbpToast.show('An unexpected error has occurred', 'negative');
        } finally {
          this.sendingVoteConfirmations = false;
          this.showVcLoadingDialog = false;
          $scope.$apply();
        }
      };

      $scope.$on('onLanguageChange', (event, lang) => {
        $scope.languageCode = lang;
        this.loadShareclasses();
      });
    },
  ],
  templateUrl:
    'features/modules/general-meeting-module/gm-vote-collector-view/voting-confirmations/voting-confirmations.template.html',
};
