/**
 * @name dwtNotificationModule
 * @description This is a module used for notification of DWT. All the
 *              navigations belonging to notification happen here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('dwtNotificationModule', ['ui.router', 'ngStorage', 'ngResource'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('dwtNotification', {
        url: '/dwtNotification',
        params: {
          selectedIndex: 2,
          eventId: null,
          issuerId: null,
          breadCrumbDetails: [],
        },
        views: {
          mainView: {
            templateUrl:
              'features/modules/dwt-module/dwt-notifications/dwt-notification.html',
            controller: 'DWTNotificationController',
          },
        },
        data: {
          nameList: ['label_taxReclaim', 'label_notification_overview'],
        },
      });
    },
  ]);
