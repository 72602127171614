/**
 * @name dwtCreateTaxReclaimEventModule
 * @description This is a module used for create and edit dwt events of DWT. All
 *              the navigations belonging to create edit dwt events happen here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('dwtCreateTaxReclaimEventModule', [
    'ui.router',
    'ngStorage',
    'ngResource',
  ])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('createDWT', {
          url: '/createDWT',
          params: {
            selectedIndex: 2,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-tax-reclaim-module/create-tax-reclaim-event.html',
              controller: 'createDwtDatePickerCtrl',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_createDWT'],
          },
        })
        .state('editDWT', {
          url: '/editDWT',
          params: {
            selectedIndex: 2,
            eventStatus: null,
            dwtPage: null,
            eventId: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-tax-reclaim-module/create-tax-reclaim-event.html',
              controller: 'createDwtDatePickerCtrl',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_editDWTevent'],
          },
        })
        .state('createDwtViewPeShareholder', {
          url: '/createDwtViewPeShareholder',
          views: {
            mainView: {
              templateUrl:
                'features/modules/user-maintenance-dwt/view-user/view-pe-shareholder-dwt.html',
              controller: 'viewPeShareholderDwtController',
              controllerAs: 'viewUserDataDwt',
            },
          },
          params: {
            selectedIndex: 2,
            userGroupId: null,
            shareholderId: null,
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_createDWT',
              'label_view_shareholder_details',
            ],
          },
        })
        .state('editDwtViewPeShareholder', {
          url: '/editDwtViewPeShareholder',
          views: {
            mainView: {
              templateUrl:
                'features/modules/user-maintenance-dwt/view-user/view-pe-shareholder-dwt.html',
              controller: 'viewPeShareholderDwtController',
              controllerAs: 'viewUserDataDwt',
            },
          },
          params: {
            selectedIndex: 2,
            userGroupId: null,
            shareholderId: null,
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_editDWTevent',
              'label_view_shareholder_details',
            ],
          },
        })
        .state('createDwtEditPeShareholder', {
          url: '/createDwtEditPeShareholder',
          views: {
            mainView: {
              templateUrl:
                'features/modules/user-maintenance-dwt/edit-user/edit-pe-shareholder-dwt.html',
              controller: 'EditPeShareholderDwtController',
              controllerAs: 'editUserDataDwt',
            },
          },
          params: {
            selectedIndex: 2,
            userGroupId: null,
            shareholderId: null,
            navFrom: null,
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_createDWT',
              'label_edit_shareholder',
            ],
          },
        })
        .state('editDwtEditPeShareholder', {
          url: '/editDwtEditPeShareholder',
          views: {
            mainView: {
              templateUrl:
                'features/modules/user-maintenance-dwt/edit-user/edit-pe-shareholder-dwt.html',
              controller: 'EditPeShareholderDwtController',
              controllerAs: 'editUserDataDwt',
            },
          },
          params: {
            selectedIndex: 2,
            userGroupId: null,
            shareholderId: null,
            navFrom: null,
            eventId: null
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_editDWTevent',
              'label_edit_shareholder',
            ],
          },
        });
    },
  ]);
