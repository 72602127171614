/**
 * @name dwtSearchShareholderModule
 * @description This is a module used for search for shareholder functionality of DWT. All
 *              the navigations belonging to search shareholder functionality happen here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('dwtSearchShareholderModule', [
    'ui.router',
    'ngStorage',
    'ngResource',
  ])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('dwtSearch', {
          url: '/dwtSearch',
          params: {
            selectedIndex: 2,
            navigatedFrom: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/dwt-search-shareholder/dwt-search-shareholder.html',
              controller: 'SearchShareholderController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_searchForShareholder'],
          },
        })
        .state('dwtSearchDetails', {
          url: '/dwtSearchDetails',
          params: {
            selectedIndex: 2,
            shareHolderId: null,
            provideFileUpload: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/dwt-search-shareholder/dwt-search-details.html',
              controller: 'SearchShareholderDetailsController',
            },
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_searchForShareholder',
              'label_dwtShareholderDetails',
            ],
          },
        });
    },
  ]);
