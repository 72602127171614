import styles from './styles.module.scss';

export default {
  templateUrl:
    '/features/modules/general-meeting-module/gm-vote-collector-view/gm-vote-collector-view.template.html',
  controller: [
    '$scope',
    'voteCollectorService',
    '$log',
    '$http',
    '$sessionStorage',
    '$timeout',
    '$filter',
    '$state',
    '$stateParams',
    function (
      $scope,
      voteCollectorService,
      $log,
      $http,
      $sessionStorage,
      $timeout,
      $filter,
      $state,
      $stateParams
    ) {
      this.$onInit = () => {
        this.styles = styles;

        if ($sessionStorage.gmLang) {
          $scope.languageCode = $sessionStorage.gmLang;
        } else {
          $scope.languageCode = 'EN';
        }
        if ($sessionStorage.status) {
          $scope.gmStatusforVC = $sessionStorage.status;
        } else {
          $scope.gmStatusforVC = 'M';
        }

        $scope.selectedIndex = 1;
        $scope.userType = $sessionStorage.usertype;
        $scope.meetingId = $scope.meetingId || $stateParams.meetingId;

        this.processVoteData();

        this.tabs = [];
        this.selectedTab = null;

        if ($scope.userType === 'IS') {
          // check if issuer is VC for any shareclass in the meeting
          voteCollectorService
            .getVoteCollectorAssignment($scope.meetingId)
            .then((response) => {
              $scope.issuerVC = response.data.length > 0;
              this.determineTabToDisplay();
            });
        } else {
          $scope.issuerVC = false;
          this.determineTabToDisplay();
        }
      }; // end of onInit()

      this.determineTabToDisplay = () => {
        if (this.shouldDisplayVotingResults()) {
          this.tabs.push({
            label: $filter('translate')('voting_result'),
            key: 'voting-results',
          });
          this.selectedTab = 'voting-results';
        }

        if (this.shouldDisplayVotingConfirmationsTab()) {
          this.tabs.push({
            label: $filter('translate')('label_votingConfirmation'),
            key: 'voting-confirmations',
          });
          if (!this.selectedTab) {
            this.selectedTab = 'voting-confirmations';
          }
        }

        if (this.shouldDisplayVotingInstructionsTab()) {
          this.tabs.push({
            label: $filter('translate')('label_viewVotingInstruction'),
            key: 'view-voting-instructions',
            disabled: false,
          });
          if (!this.selectedTab) {
            this.selectedTab = 'view-voting-instructions';

            if (this.selectedShareClassId) {
              this.handleTabChange('view-voting-instructions');
            }
          }
        }
      };

      this.handleTabChange = (tab) => {
        if (tab === 'view-voting-instructions') {
          $state.go('viewVotingVC', {
            type: 'votingInstruction',
            shareclassId: this.selectedShareClassId,
            meetingId: $scope.meetingId,
            status: $scope.gmStatusforVC,
            landingThrough: 'votecollectorTab',
            selectedIndex: $scope.selectedIndex,
            participationMethod: null,
            issuerVc: $scope.issuerVC,
          });
        }
        this.selectedTab = tab;
      };

      this.shouldDisplayVotingResults = () => {
        return (
          $scope.userType === 'VC' ||
          $scope.userType === 'AG' ||
          ($scope.userType === 'IS' && $scope.issuerVC === true) ||
          ($scope.userType === 'IS' && $scope.gmStatusforVC === 'M')
        );
      };

      this.shouldDisplayVotingConfirmationsTab = () => {
        return (
          ($scope.displayVoteConfirmTab && $scope.gmStatusforVC === 'M') ||
          ($scope.gmStatusforVC === 'M' &&
            ($scope.userType === 'VC' ||
              $scope.userType === 'AG' ||
              ($scope.userType === 'IS' && $scope.issuerVC === true)))
        );
      };

      this.shouldDisplayVotingInstructionsTab = () => {
        return (
          $scope.gmStatusforVC === 'L' ||
          $scope.gmStatusforVC === 'M' ||
          $scope.userType === 'VC'
        );
      };

      this.processVoteData = () => {
        if ($scope.userType === 'VC') {
          $scope.selectedIndex = 0;
          $scope.meetingId = $stateParams.data.meetingId;
          voteCollectorService
            .getVCShareclassList(
              $scope.meetingId,
              $scope.userType,
              $scope.languageCode
            )
            .then((response) => {
              $scope.shareClass = response.data;
              if ($scope.shareClass.length > 0) {
                this.selectedShareClassId = $scope.shareClass[0].shareClassId;
              }
            });
        } else if ($stateParams.status === 'M') {
          $scope.shareClass = $sessionStorage.gmShareclassList;
          this.selectedShareClassId = $scope.shareClass[0].shareClassId;
        } else {
          voteCollectorService
            .getVCShareclassList(
              $scope.meetingId,
              $scope.userType,
              $scope.languageCode
            )
            .then((response) => {
              $scope.shareClass = response.data;
              if ($scope.shareClass.length > 0) {
                this.selectedShareClassId = $scope.shareClass[0].shareClassId;

                if (this.selectedTab === 'view-voting-instructions') {
                  this.handleTabChange('view-voting-instructions');
                }
              }
            });
        }
      };

      $scope.$on('onLanguageChange', (event, lang) => {
        $scope.languageCode = lang;
        this.processVoteData();
      });
    },
  ],
};
