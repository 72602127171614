import { trimEncodedKey } from '@/lib/encodedKey';

export default {
  bindings: {
    meetingId: '<',
  },
  controller: [
    '$scope',
    '$http',
    'cbpToast',
    function ($scope, $http, cbpToast) {
      this.$onInit = () => {
        this.loading = false;
        this.isSent = false;
        this.latestRln = null;

        this.getRegistrationListNotifications().finally(() => {
          $scope.$apply();
        });
      };

      this.getRegistrationListNotifications = async () => {
        const { data } = await $http.get(
          `/v2/gm/agent/general-meetings/${trimEncodedKey(
            this.meetingId
          )}/registration-list-notifications`
        );

        if (data.length) {
          this.latestRln = data[data.length - 1];
          this.isSent = true;
        } else {
          this.isSent = false;
        }
      };

      this.openModal = () => {
        document
          .querySelector('#registrationListNotificationModal')
          .openModal();
      };

      this.closeModal = () => {
        document
          .querySelector('#registrationListNotificationModal')
          .closeModal(new Event('click'));
      };

      this.handleSendButton = async () => {
        try {
          this.sending = true;
          const { data } = await $http.post(
            `/v2/gm/agent/general-meetings/${trimEncodedKey(
              this.meetingId
            )}/registration-list-notifications/trigger-notification-email`
          );
          this.latestRln = data;
          this.isSent = true;
          cbpToast.show(
            'Registration list notification has been sent',
            'success'
          );
        } catch {
          cbpToast.show('An error occured', 'error');
        } finally {
          this.sending = false;
          this.closeModal();
          $scope.$apply();
        }
      };
    },
  ],
  templateUrl:
    '/features/modules/general-meeting-module/registrations-tab/registration-list-notification-view/registration-list-notification-view.template.html',
};
