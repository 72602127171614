module.exports = {
  bindings: {
    onClose: '&',
  },
  controller: [
    '$timeout',
    function ($timeout) {
      this.$onInit = () => {
        const totalSecondsBeforeInfo = 10;
        const totalSecondsBeforeClose = 115;

        this.secondsToGo = totalSecondsBeforeClose;
        this.showWaitingInfo = false;

        // show waiting info after 'totalSecondsBeforeInfo' seconds
        this.waitingInfoTimeout = $timeout(() => {
          this.showWaitingInfo = true;
        }, totalSecondsBeforeInfo * 1000);

        // close dialog after 'totalSecondsBeforeClose' seconds
        this.closeTimeout = $timeout(() => {
          this.onClose();
        }, totalSecondsBeforeClose * 1000); // wait almost 2 minutes (before automatic timeout occurs

        // update close button label every second with a countdown timer
        this.closeTimer = setInterval(() => {
          --this.secondsToGo;
          let closeButtonLabel = document.getElementById('closeButtonTextId');
          if (closeButtonLabel) {
            closeButtonLabel.innerText = `Close (${this.secondsToGo} second(s))`;
          }
          if (this.secondsToGo == 0) {
            clearInterval(this.closeTimer);
          }
        }, 1000);
      };

      this.$onDestroy = () => {
        if (this.waitingInfoTimeout) {
          $timeout.cancel(this.waitingInfoTimeout);
        }
        if (this.closeTimeout) {
          $timeout.cancel(this.closeTimeout);
        }
        if (this.closeTimer) {
          clearInterval(this.closeTimer);
        }
      };
    },
  ],
  templateUrl:
    'features/modules/general-meeting-module/gm-vote-collector-view/voting-confirmations/vote-confirmations-loading-dialog/vote-confirmations-loading-dialog.template.html',
};
