// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3s70DMhgD6wYDrEOvliEMc{background-color:#fffbe6}._2oaO6dASTeEOQhobqt3efV{display:flex;padding:0 20px 24px;border-bottom:1px solid #dedede}", ""]);
// Exports
exports.locals = {
	"sendContainer": "_3s70DMhgD6wYDrEOvliEMc",
	"emailPreviewSubjectContainer": "_2oaO6dASTeEOQhobqt3efV"
};
module.exports = exports;
