/**
 * @name
 * @description This is a module used for create extension request of DWT. All
 *              the navigations belonging to create extension request happen
 *              here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('dwtCreateExtensionModule', ['ui.router', 'ngStorage', 'ngResource'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('createDwtExtensionRequest', {
          url: '/createDwtExtensionRequest',
          params: {
            selectedIndex: 2,
            eventName: null,
            issuerName: null,
            eventId: null,
            extensionRequestId: null,
            issuerId: null,
            shareClassName: null,
            isin: null,
            paymentDate: null,
            qrfEndDate: null,
            peFlag: null,
            qrfFlag: null,
            peExtReqFlag: null,
            qrfExtReqFlag: null,
            peEndDate: null,
            peEndDateStr: null,
            qrfEndDateStr: null,
            taSubmissionDate: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-dwt-extension-request/create-dwt-extension-request.html',
              controller: 'CreateDwtExtensionRequestController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_create_extension_request'],
          },
        })
        .state('editDwtExtensionRequest', {
          url: '/editDwtExtensionRequest',
          params: {
            selectedIndex: 2,
            eventName: null,
            issuerName: null,
            eventId: null,
            extensionRequestId: null,
            issuerId: null,
            shareClassName: null,
            isin: null,
            paymentDate: null,
            qrfEndDate: null,
            peFlag: null,
            qrfFlag: null,
            peEndDate: null,
            peEndDateStr: null,
            qrfEndDateStr: null,
            reason: null,
            dwtType: null,
            endDate: null,
            endDateString: null,
            taSubmissionDate: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-dwt-extension-request/create-dwt-extension-request.html',
              controller: 'CreateDwtExtensionRequestController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_edit_extn_request'],
          },
        })
        .state('createDwtExtensionRequestDetail', {
          url: '/createDwtExtensionRequestDetail',
          params: {
            selectedIndex: 2,
            eventName: null,
            issuerName: null,
            eventId: null,
            extensionRequestId: null,
            issuerId: null,
            shareClassName: null,
            isin: null,
            paymentDate: null,
            qrfEndDate: null,
            peFlag: null,
            qrfFlag: null,
            peExtReqFlag: null,
            qrfExtReqFlag: null,
            peEndDate: null,
            peEndDateStr: null,
            qrfEndDateStr: null,
            taSubmissionDate: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-dwt-extension-request/create-dwt-extension-request.html',
              controller: 'CreateDwtExtensionRequestController',
            },
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_dwtEventDetails',
              'label_create_extension_request',
            ],
          },
        });
    },
  ]);
