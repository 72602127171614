/**
 * @name myAccountsModule
 * @description This is a module used for change password and to update/view the user profile . All the navigations
 *              belonging to my accounts happen here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('myAccountsModule', [
    'ui.router',
    'ngStorage',
    'ngResource',
    'gmModal',
  ])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('myAccounts', {
        url: '/myAccounts',
        params: {
          selectedIndex: 2,
        },
        data: {
          nameList: ['item2'],
        },
        views: {
          mainView: {
            templateUrl:
              'features/modules/my-accounts-module/my-account-agent.html',
            controller: 'MyAccountController',
          },
        },
        resolve: {
          resolveProfileData: [
            'MyAccountsService',
            '$sessionStorage',
            function (MyAccountsService, $sessionStorage) {
              return MyAccountsService.getProfileData(
                $sessionStorage.userId,
                $sessionStorage.usertype
              ).then(
                function (response) {
                  return response.data;
                },
                function (error) {
                  return error;
                }
              );
            },
          ],
        },
      });
    },
  ]);
