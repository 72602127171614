/**
 * @ngdoc factory
 * @name recaptchaInfoModule
 * @description This is a module used to retrieve recaptcha keys from the server
 */

(function () {
  'use strict';
  var app = angular.module('recaptchaInfoModule', []);
  // This factory defines the methods to invoke http operations
  app.factory('RecaptchaService', [
    '$resource',
    'RecaptchaInfoService',
    function ($resource, RecaptchaInfoService) {
      return {
        getKeys: function () {
          var captchaResource = $resource(
            'v1/recaptcha/keys',
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
          return captchaResource.query({}).$promise.then(function (result) {
            return RecaptchaInfoService.captchainfo(result);
          });
        },
      };
    },
  ]);

  // This service defines methods to construct and read Model data
  app.service('RecaptchaInfoService', function () {
    this.captchainfo = function (jsonData) {
      return {
        siteKey: jsonData.siteKey,
      };
    };
  });
})();
