import './vote-confirmations-table.scss';

export default {
  bindings: {
    voteConfirmations: '<',
  },
  controller: [function () {}],
  templateUrl:
    '/features/modules/general-meeting-module/gm-vote-collector-view/voting-confirmations/confirmed-votes/vote-confirmations-table.template.html',
};
