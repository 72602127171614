import moment from 'moment-timezone';

export const formatDateToDateTime = (dateOrDateString) => {
  const date = new Date(dateOrDateString);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const yyyyMmDdToDdMmYyyy = (yyyyMmDd) => {
  if (!yyyyMmDd) {
    return '';
  }
  const [yyyy, mm, dd] = yyyyMmDd.split('-');
  return `${dd}-${mm}-${yyyy}`;
};

export const formatDateTimeNoTimezone = (iso8601DateStr, languageCode) => {
  let localLocale = moment(iso8601DateStr);
  let format = 'MMM D, YYYY H:mm z';

  switch (languageCode) {
    case 'NL':
      localLocale.locale('nl');
      format = 'D MMM, YYYY H:mm z';
      break;
    case 'FR':
      localLocale.locale('fr');
      format = 'D MMM, YYYY H:mm z';
      break;
    default:
      localLocale.locale('en');
  }

  return localLocale.utcOffset(0, false).format(format);
};

export const formatDateTime = (iso8601DateStr, timezone, languageCode) => {
  let localLocale = moment(iso8601DateStr);
  let format = 'MMM D, YYYY H:mm z';

  switch (languageCode) {
    case 'NL':
      localLocale.locale('nl');
      format = 'D MMM, YYYY H:mm z';
      break;
    case 'FR':
      localLocale.locale('fr');
      format = 'D MMM, YYYY H:mm z';
      break;
    case 'DE':
      localLocale.locale('de');
      format = 'DD.MMMM.YYYY H:mm z';
      break;
    case 'ES':
      localLocale.locale('es');
      // TO do: confirm this with user and update
      format = 'D MMM, YYYY H:mm z';
      break;
    default:
      localLocale.locale('en');
  }

  localLocale.tz(getCanonicalTimezone(timezone));

  return localLocale.format(format);
};

export const formatDate = (iso8601DateStr, languageCode) => {
  let localLocale = moment(iso8601DateStr);
  let format = 'D MMM YYYY';

  switch (languageCode) {
    case 'NL':
      localLocale.locale('nl');
      break;
    case 'FR':
      localLocale.locale('fr');
      break;
    case 'DE':
      localLocale.locale('de');
      format = 'DD.MMMM.YYYY';
      break;
    case 'ES':
      localLocale.locale('es');
      break;
    default:
      localLocale.locale('en');
      format = 'MMM D, YYYY';
  }

  return localLocale.format(format);
};

const getCanonicalTimezone = (timezone) => {
  if (timezone == 'CEST') {
    // moment has no entry for CEST
    return 'CET';
  }
  return timezone;
};
