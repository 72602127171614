/**
 * @name dwtCreateQRFClaimModule
 * @description This is a module used for create QRF claim of DWT. All the
 *              navigations belonging to QRF claim happen here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('dwtCreateQRFClaimModule', ['ui.router', 'ngStorage', 'ngResource'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('createQRFClaim', {
          url: '/createQRFClaim',
          params: {
            selectedIndex: 2,
            peClaimId: null,
            eventId: null,
            shareholderId: null,
            intermediaryId: null,
            loginUserId: null,
            isin: null,
            dwtPerSecurity: null,
            issuerId: null,
            flag: null,
            eventStatus: null,
            eventName: null,
            issuerName: null,
            qrfEndDate: null,
            extReqFlag: null,
            extensionReqFlagForQRF: null,
            extensionReqFlagForPE: null,
            shareClassName: null,
            qrfFlag: null,
            peFlag: null,
            shareClassId: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-dwt-qrf-claim/create-dwt-qrf-claim.html',
              controller: 'CreateDwtQRFRequestController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_submitQRFClaim'],
          },
        })
        .state('editQRFClaim', {
          url: '/editQRFClaim',
          params: {
            selectedIndex: 2,
            qrfClaimId: null,
            eventId: null,
            shareholderId: null,
            intermediaryId: null,
            loginUserId: null,
            isin: null,
            flag: null,
            dwtPerSecurity: null,
            eventName: null,
            issuerId: null,
            issuerName: null,
            eventStatus: null,
            qrfEndDate: null,
            extensionReqFlagForQRF: null,
            extensionReqFlagForPE: null,
            extReqFlag: null,
            qrfFlag: null,
            peFlag: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-dwt-qrf-claim/create-dwt-qrf-claim.html',
              controller: 'CreateDwtQRFRequestController',
            },
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_dwtEventClaims',
              'label_editQRFClaim',
            ],
          },
        });
    },
  ]);
