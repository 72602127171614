/**
 * @name
 * @description This is a module used for View - approve/reject extension request of DWT. All the navigations
 *              belonging to  View - approve/reject extension request happen here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('dwtApproveRejectExtensionModule', [
    'ui.router',
    'ngStorage',
    'ngResource',
  ])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('dwtExtensionRequest', {
          url: '/dwtExtensionRequest',
          params: {
            selectedIndex: 2,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/dwt-approve-reject-extension-request/dwt-view-extension.html',
              controller: 'DwtExtController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_extReq'],
          },
        })
        .state('dwtSingleExtensionRequest', {
          url: '/dwtSingleExtensionRequest',
          params: {
            selectedIndex: 2,
            peEndDate: null,
            peEndDateStr: null,
            qrfEndDate: null,
            peFlag: null,
            qrfFlag: null,
            eventName: null,
            issuerName: null,
            eventId: null,
            issuerId: null,
            shareClassName: null,
            isin: null,
            paymentDate: null,
            taSubmissionDate: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/dwt-approve-reject-extension-request/dwt-view-single-extension.html',
              controller: 'DwtSingleExtController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_extReq'],
          },
        });
    },
  ]);
