/**
 * @name dwtQrfBulkUploadModule
 * @description This is a module used for QRF bulk upload of DWT. All the
 *              navigations belonging to PE claim happen here
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('dwtQrfBulkUploadModule', ['ui.router', 'ngStorage', 'ngResource'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('qrfBulkUpload', {
          url: '/qrfBulkUpload',
          params: {
            selectedIndex: 2,
            eventId: null,
            issuerId: null,
            eventName: null,
            isin: null,
            issuerName: null,
            shareholderId: null,
            dwtPerSecurity: null,
            flag: null,
            eventStatus: null,
            qrfEndDate: null,
            extReqFlag: null,
            extensionReqFlagForQRF: null,
            extensionReqFlagForPE: null,
            shareClassName: null,
            shareClassId: null,
            qrfFlag: null,
            peFlag: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-dwt-bulk-upload-qrf-claim/create-dwt-qrf-bulk-upload.html',
              controller: 'DwtBulkUploadController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_bulkUploadQRF'],
          },
        })
        .state('qrfBulkUploadDetail', {
          url: '/qrfBulkUploadDetail',
          params: {
            selectedIndex: 2,
            eventId: null,
            issuerId: null,
            eventName: null,
            isin: null,
            issuerName: null,
            shareholderId: null,
            dwtPerSecurity: null,
            flag: null,
            eventStatus: null,
            qrfEndDate: null,
            extReqFlag: null,
            extensionReqFlagForQRF: null,
            extensionReqFlagForPE: null,
            shareClassName: null,
            shareClassId: null,
            qrfFlag: null,
            peFlag: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/create-dwt-bulk-upload-qrf-claim/create-dwt-qrf-bulk-upload.html',
              controller: 'DwtBulkUploadController',
            },
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_dwtEventDetails',
              'label_bulkUploadQRF',
            ],
          },
        });
    },
  ]);
