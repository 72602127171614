/**
 * @name dwtModule
 * @description dwtModule used for routing
 */
'use strict';
angular
  .module('dwtModule', [
    'ui.router',
    'ngStorage',
    'commonModule',
    'ngResource',
    'ui.bootstrap',
    'ngAnimate',
    'ngSanitize',
    'UIControlModule',
    'richTextEditorModule',
    'gmModal',
    'myAccountProfile',
    'MenuModule',
    'dwtAuditTrailModule',
    'dwtCreateExtensionModule',
    'dwtApproveRejectExtensionModule',
    'dwtCreateTaxReclaimEventModule',
    'dwtCreateQRFClaimModule',
    'dwtCreatePEClaimModule',
    'dwtNotificationModule',
    'dwtSearchShareholderModule',
    'dwtQrfBulkUploadModule',
  ])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('taxReclaimHome', {
          url: '/taxReclaimHome',
          params: {
            selectedIndex: 2,
            eventId: null,
            shareholderId: null,
            intermediaryId: null,
            loginUserId: null,
            isin: null,
            dwtPerSecurity: null,
            issuerId: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/dwt-landing-module/dwt-landing-home.html',
              controller: 'DwtLandingController',
            },
          },
          data: {
            nameList: ['label_taxReclaim'],
          },
        })
        .state('dwtAudit', {
          url: '/dwtAudit',
          params: {
            selectedIndex: 2,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/tax-reclaim-audit-trail-module/dwt-admin-audit-trail.html',
              controller: 'TaxReclaimAuditController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_auditAndTemplate'],
          },
        })
        .state('dwtDetails', {
          url: '/dwtDetails',
          params: {
            selectedIndex: 2,
            flag: null,
            eventId: null,
            issuerId: null,
            issuerName: null,
            dwtPerSecurity: null,
            eventName: null,
            eventStatus: null,
            qrfEndDate: null,
            peEndDate: null,
            peFlag: null,
            qrfFlag: null,
            extReqFlag: null,
            extensionReqFlagForQRF: null,
            extensionReqFlagForPE: null,
            shareholderId: null,
            navFrom: null,
            taSubmissionDate: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/view-dwt-details/dwt-details-home.html',
              controller: 'DwtDetailsHomeController',
            },
          },
        })
        .state('dwtDetails.dwtEventDetails', {
          url: '/dwtEventDetails',
          views: {
            dwtEventDetails: {
              templateUrl:
                'features/modules/dwt-module/view-dwt-details/dwt-event-details.html',
              controller: 'DwtEventDetailsController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_dwtEventDetails'],
          },
        })
        .state('dwtDetails.dwtEventClaims', {
          url: '/dwtEventClaims',
          views: {
            dwtEventDetails: {
              templateUrl:
                'features/modules/dwt-module/view-dwt-details/dwt-event-claims.html',
              controller: 'DwtEventClaimsController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_dwtEventClaims'],
          },
        })
        .state('dwtDetails.dwtPaymentDetails', {
          url: '/dwtPaymentDetails',
          views: {
            dwtEventDetails: {
              templateUrl:
                'features/modules/dwt-module/view-dwt-details/dwt-payment-details.html',
              controller: 'DwtPaymentDetailsController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_dwtPaymentDetails'],
          },
        })
        .state('dwtDetails.emailTaxAuthority', {
          url: '/emailTaxAuthority',
          views: {
            dwtEventDetails: {
              templateUrl:
                'features/modules/dwt-module/view-dwt-details/dwt-send-email-tax-authority.html',
              controller: 'DwtSendEmailController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'sendEmail_taxAuthority'],
          },
        })
        .state('dwtDetails.dwtToBeAuthorized', {
          url: '/toBeAuthorized',
          views: {
            dwtEventDetails: {
              templateUrl:
                'features/modules/dwt-module/view-dwt-details/dwt-to-be-authorized.html',
              controller: 'DwtToBeAuthorizedController',
            },
          },
          data: {
            nameList: ['label_taxReclaim', 'label_toBeAuthorised'],
          },
        })
        .state('dwtPEClaimDetails', {
          url: '/dwtPEClaimDetails',
          params: {
            selectedIndex: 2,
            flag: null,
            eventId: null,
            claimId: null,
            issuerId: null,
            issuerName: null,
            claimsArray: [],
            countNo: null,
            eventName: null,
            eventStatus: null,
            dwtPerSecurity: null,
            qrfEndDate: null,
            peEndDate: null,
            navFrom: null,
            peFlag: null,
            qrfFlag: null,
            extensionReqFlagForQRF: null,
            extensionReqFlagForPE: null,
            extReqFlag: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/view-dwt-details/dwt-claim-detailed-view.html',
              controller: 'DetailedViewClaimController',
            },
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_dwtEventClaims',
              'lable_PEClaimDetailedView',
            ],
          },
        })
        .state('dwtQRFClaimDetails', {
          url: '/dwtQRFClaimDetails',
          params: {
            selectedIndex: 2,
            flag: null,
            eventId: null,
            claimId: null,
            issuerId: null,
            issuerName: null,
            claimsArray: [],
            countNo: null,
            eventName: null,
            eventStatus: null,
            dwtPerSecurity: null,
            qrfEndDate: null,
            peEndDate: null,
            navFrom: null,
            peFlag: null,
            qrfFlag: null,
            extensionReqFlagForQRF: null,
            extensionReqFlagForPE: null,
            extReqFlag: null,
            form6166Id: null,
            formIB9296Id: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/view-dwt-details/dwt-claim-detailed-view.html',
              controller: 'DetailedViewClaimController',
            },
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_dwtEventClaims',
              'lable_QRFClaimDetailedView',
            ],
          },
        })
        .state('addPeShareholder', {
          url: '/addPeShareholder',
          views: {
            mainView: {
              templateUrl:
                'features/modules/user-maintenance-dwt/add-user/add-pe-shareholder-dwt.html',
              controller: 'AddPeShareholderDwtController',
              controllerAs: 'addUserDataDwt',
            },
          },
          params: {
            selectedIndex: 2,
            userGroupId: null,
            navFrom: null,
            eventId: null,
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_dwtEventDetails',
              'label_add_shareholder',
            ],
          },
        })
        .state('viewPeShareholder', {
          url: '/viewPeShareholder',
          views: {
            mainView: {
              templateUrl:
                'features/modules/user-maintenance-dwt/view-user/view-pe-shareholder-dwt.html',
              controller: 'viewPeShareholderDwtController',
              controllerAs: 'viewUserDataDwt',
            },
          },
          params: {
            selectedIndex: 2,
            userGroupId: null,
            shareholderId: null,
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_dwtEventDetails',
              'label_view_shareholder_details',
            ],
          },
        })
        .state('editPeShareholder', {
          url: '/editPeShareholder',
          views: {
            mainView: {
              templateUrl:
                'features/modules/user-maintenance-dwt/edit-user/edit-pe-shareholder-dwt.html',
              controller: 'EditPeShareholderDwtController',
              controllerAs: 'editUserDataDwt',
            },
          },
          params: {
            selectedIndex: 2,
            userGroupId: null,
            shareholderId: null,
            navFrom: null,
            eventId: null,
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_dwtEventDetails',
              'label_edit_shareholder',
            ],
          },
        })
        .state('createDwtAddPeShareholder', {
          url: '/createDwtAddPeShareholder',
          views: {
            mainView: {
              templateUrl:
                'features/modules/user-maintenance-dwt/add-user/add-pe-shareholder-dwt.html',
              controller: 'AddPeShareholderDwtController',
              controllerAs: 'addUserDataDwt',
            },
          },
          params: {
            selectedIndex: 2,
            userGroupId: null,
            shareclassId: null,
            navFrom: null,
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_createDWT',
              'label_add_shareholder',
            ],
          },
        })
        .state('editDwtAddPeShareholder', {
          url: '/editDwtAddPeShareholder',
          views: {
            mainView: {
              templateUrl:
                'features/modules/user-maintenance-dwt/add-user/add-pe-shareholder-dwt.html',
              controller: 'AddPeShareholderDwtController',
              controllerAs: 'addUserDataDwt',
            },
          },
          params: {
            selectedIndex: 2,
            userGroupId: null,
            shareclassId: null,
            shareholderId: null,
            navFrom: null,
            eventId: null,
          },
          data: {
            nameList: [
              'label_taxReclaim',
              'label_editDWTevent',
              'label_add_shareholder',
            ],
          },
        })
        .state('liveEventsDetailedOverview', {
          url: '/liveEventsDetailedOverview',
          views: {
            mainView: {
              templateUrl:
                'features/modules/dwt-module/dwt-landing-module/live-events-detailed-overview.html',
              controller: [
                '$scope',
                '$state',
                '$sessionStorage',
                '$filter',
                '$log',
                'DwtHomeService',
                'PaginationSvc',
                function (
                  $scope,
                  $state,
                  $sessionStorage,
                  $filter,
                  $log,
                  DwtHomeService,
                  PaginationSvc
                ) {
                  $scope.searchViewPage = false;
                  $scope.sortType = 'issuer';
                  $scope.sortReverse = false;
                  $scope.liveEventLoadingFlag = true;
                  DwtHomeService.getLiveEventDetailedOverView().then(
                    function (data) {
                      $scope.dwtLiveEventDetailedOverViewData = data.data;
                      $scope.dwtLiveEventDetailedOverViewData.displayRowCount = 0;
                      $scope.dwtLiveEventDetailedOverViewDataDisplay = [];
                      angular.forEach(
                        $scope.dwtLiveEventDetailedOverViewData,
                        function (value1) {
                          if (
                            value1.peEndDate === null ||
                            value1.peEndDate === undefined ||
                            $filter('date')(
                              new Date(value1.peEndDate),
                              'yyyy-MM-dd'
                            ) === '1970-01-01'
                          ) {
                            value1.peEndDateStr = '';
                          } else {
                            value1.peEndDateStr = value1.peEndDateStr + ' CET';
                          }
                          if (
                            value1.qrfEndDate === null ||
                            value1.qrfEndDate === undefined ||
                            $filter('date')(
                              new Date(value1.qrfEndDate),
                              'yyyy-MM-dd'
                            ) === '1970-01-01'
                          ) {
                            value1.qrfEndDateStr = '';
                          } else {
                            value1.qrfEndDateStr =
                              value1.qrfEndDateStr + ' CET';
                          }
                          if (value1.claimDetailsVO) {
                            angular.forEach(
                              value1.claimDetailsVO.claimOverviewList,
                              function (value2) {
                                value2.data = value1;
                                $scope.dwtLiveEventDetailedOverViewDataDisplay.push(
                                  value2
                                );
                              }
                            );
                          }
                        }
                      );

                      $scope.dummyItems =
                        $scope.dwtLiveEventDetailedOverViewDataDisplay;
                      $scope.pager = {};
                      $scope.setPage(1);
                      $scope.liveEventLoadingFlag = false;
                      $log.debug('success');
                    },
                    function (error) {
                      $scope.liveEventLoadingFlag = false;
                      $log.debug(error);
                    }
                  );

                  $scope.setPage = function (page) {
                    $scope.currentPage = page;
                    $scope.pager = [];
                    if (page < 1 || page > $scope.pager.totalPages) {
                      return;
                    }

                    // get pager object from service
                    $scope.pager = PaginationSvc.GetPager(
                      $scope.dummyItems.length,
                      page
                    );
                    $log.debug($scope.pager);

                    // get current page of items
                    $scope.dwtLiveEventDetailedOverViewPageData = $scope.dummyItems.slice(
                      $scope.pager.startIndex,
                      $scope.pager.endIndex + 1
                    );
                    $scope.beginIndex = $scope.pager.startIndex;
                    $scope.pageNo = page;
                  };

                  $scope.prodType = $sessionStorage.productType;
                  // Added for preventing redirection
                  // from Action Home page
                },
              ],
            },
          },
          params: {
            selectedIndex: 2,
          },
          data: {
            nameList: ['label_taxReclaim', 'label_live_events_overview'],
          },
        });
    },
  ]);
