(function () {
  'use strict';
  /**
   * State Manager of the login module
   */
  angular.module('loginRoute', ['ui.router', 'pascalprecht.translate']).config([
    '$httpProvider',
    '$translateProvider',
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',
    function (
      $httpProvider,
      $translateProvider,
      $stateProvider,
      $urlRouterProvider,
      $locationProvider
    ) {
      $urlRouterProvider.otherwise('404');
      $stateProvider
        .state('agent', {
          url: '/agent',
          views: {
            mainView: {
              template: "<login-form workstation='Agent' usertype='AG'>",
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('issuer', {
          url: '/issuer',
          views: {
            mainView: {
              template: "<login-form workstation='Issuer' usertype='IS'>",
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('intermediary', {
          url: '/intermediary',
          views: {
            mainView: {
              template: "<login-form workstation='Intermediary' usertype='IN'>",
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('votecollector', {
          url: '/votecollector',
          views: {
            mainView: {
              template:
                "<login-form workstation='Vote collector' usertype='VC'>",
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('shareholderlogin', {
          url: '/shareholderlogin',
          params: {
            selectedIndex: 0,
          },
          views: {
            mainView: {
              template: "<login-form workstation='Shareholder' usertype='SH'>",
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('shareplan', {
          url: '/shareplan',
          views: {
            mainView: {
              template: "<login-form workstation='Shareplan' usertype='SP'>",
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('tokenlogin', {
          url: '/tokenlogin',
          views: {
            mainView: {
              templateUrl: 'features/modules/password-reset/login-token.html',
              controller: 'TokenController',
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('resetPassword', {
          url: '/resetPassword',
          params: {
            selectedIndex: 0,
            passwordResetData: null,
            location: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/password-reset/reset-password.html',
              controller: 'ResetPasswordController',
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('changePassword', {
          url: '/changePassword',
          params: {
            selectedIndex: 0,
            passwordResetData: null,
            passwordExpired: true,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/password-reset/reset-password.html',
              controller: 'ResetPasswordController',
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('forgotPassword', {
          url: '/forgotPassword',
          views: {
            mainView: {
              templateUrl:
                'features/modules/password-reset/forgot-password-success.html',
              controller: 'TokenController',
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('registerSH', {
          url: '/registerSH',
          params: {
            selectedIndex: 0,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/shareholder-registration-module/register-shareholder.html',
              controller: 'captchaCtrl',
              controllerAs: 'c1',
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('home', {
          url: '/',
          views: {
            mainView: {
              component: 'workstationSelection',
            },
          },
        })
        // alias for 'home'
        .state('welcomePage', {
          url: '/welcomePage',
          views: {
            mainView: {
              component: 'workstationSelection',
            },
          },
        })
        .state('404', {
          url: '/404',
          views: {
            mainView: {
              component: 'notFoundPage',
            },
          },
        })
        .state('termsUse', {
          url: '/termsUse',
          views: {
            mainView: {
              templateUrl: 'features/modules/footer/terms-and-conditions.html',
              controller: 'termsAndConditionsCtrl',
            },
          },
        })
        .state('contactDetails', {
          url: '/contactDetails',
          params: {
            userType: 'AG',
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/contact-details/contact-details.html',
              controller: 'ContactDetailsController',
            },
          },
        });
      $locationProvider.html5Mode({
        enabled: true,
        requireBase: false,
      });
    },
  ]);
})();
